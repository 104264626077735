import React, { useEffect, useState } from 'react';
import fb from '../../assets/img/footer_facebook.svg';
import linkedIn from '../../assets/img/footer_linkedin.svg';
import twt from '../../assets/img/footer_twitter.svg';
import yt from '../../assets/img/footer_youtube.svg';
import skype from '../../assets/img/footer_skype.svg';
import gplus from '../../assets/img/footer_google-plus.svg';
import footerLogo from '../../assets/img/footer_logo.svg';
import curveWhite from '../../assets/img/section_curve_white.svg';
import curveGrey from '../../assets/img/section_curve_grey.svg';
import { Link, useLocation } from 'react-router-dom';

const DefaultFooter = (props) => {
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    if (
      path === '/work' ||
      path === '/event' ||
      path === '/contact-us' ||
      path === '/blog' ||
      path === '/quality-assurance' ||
      path === '/seo' ||
      path === '/project-details' ||
      path === '/event-details'
    ) {
      setfooterImg(curveGrey);
    } else {
      setfooterImg(curveWhite);
    }
  }, [path]);
  const [footerImg, setfooterImg] = useState(curveWhite);
  return (
    <footer
      className={
        path === '/work' ||
        '/event' ||
        '/contact-us' ||
        '/blog' ||
        '/seo' ||
        '/project-details' ||
        '/event-details' ||
        'quality-assurance'
          ? 'mt-0'
          : 'mt-5'
      }
    >
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 col-md-3 col-sm-6 col-12 footer_01 fiiter_custm_margin'>
            <div className='footer_01_logo'>
              <img src={footerLogo} alt='' />
            </div>
            <p className='mail_title'>Get Free Estimation</p>
            <p className='mail_id'>
              <a href='mailto:inquiry@saturncube.com' style={{ color: '#fff' }}>
                inquiry@saturncube.com
              </a>
            </p>
            <div className='footer_soccial_icon'>
              {/* <Link to='#'> */}
              <a
                href='https://www.facebook.com/saturncube.technologies'
                target='_blank'
                rel='noreferrer'
              >
                <img src={fb} className='ml-0' alt='' />
              </a>
              {/* </Link> */}
              <a
                href='https://www.linkedin.com/company/saturncube-technologies'
                target='_blank'
                rel='noreferrer'
              >
                <img src={linkedIn} alt='' />
              </a>
              <a
                href='https://twitter.com/SaturnCube'
                target='_blank'
                rel='noreferrer'
              >
                <img src={twt} alt='' />
              </a>
              {/* <Link to='#'>
                <img src={yt} alt='' />
              </Link>
              <Link to='#'>
                <img src={skype} alt='' />
              </Link>
              <Link to='#'>
                <img src={gplus} alt='' />
              </Link> */}
            </div>
          </div>
          <div className='col-lg-3 col-md-3 col-sm-3 col-6 footer_02 fiiter_custm_margin fiiter_custm_pading'>
            {/* <p>Links</p> */}
            <ul>
              {/* <li>
                <Link to='/career'>Careers</Link>
              </li>
              <li>
                <Link to='#'>About Us</Link>
              </li> */}
              {/* <li>
                <Link to='/services'>Services</Link>
              </li> */}
              {/* <li>
                <Link to='/portfolio'>Portfolio</Link>
              </li>
              <li>
                <Link to='/event'>Events</Link>
              </li>
              <li>
                <Link to='/testimonial'>Testimonial</Link>
              </li>
              <li>
                <Link to='/blog'>Blog</Link>
              </li> */}
            </ul>
          </div>
          <div className='col-lg-2 col-md-2 col-sm-3 col-6 footer_02 fiiter_custm_margin'>
            {/* <p>Recent Projects</p> */}
            {/* <ul>
              <li>
                <Link to="#">Lorem</Link>
              </li>
              <li>
                <Link to="#">WayPoints</Link>
              </li>
              <li>
                <Link to="#">StidentHOB</Link>
              </li>
              <li>
                <Link to="#">Lorem ipsum</Link>
              </li>
              <li>
                <Link to="#">Doler sit</Link>
              </li>
              <li>
                <Link to="#">Free</Link>
              </li>
              <li>
                <Link to="#">Dating</Link>
              </li>
            </ul> */}
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 col-12 footer_03 fiiter_custm_margin'>
            <p>Stay Updated</p>
            <input type='type' name='email' placeholder='Email' />
            <button>Send</button>
          </div>
          <div className='col-lg-12 footer_04'>
            <p>
              © 2022 Copyright SaturnCube Technologies. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
      <img className='sec_curve_img' src={footerImg} alt='' />
    </footer>
  );
};
export default DefaultFooter;

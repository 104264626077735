import React, { Fragment, useState } from 'react';
import ContactUsLottie from '../../Components/Lottie/ContactUsLottie';

const ContactUs = () => {
  const [state, setstate] = useState({ name: '', email: '', message: '' });

  const handleInputChange = (e) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Fragment>
      <div className='contact_us_01 mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 wow animate__animated animate__fadeInLeft'>
              <p className='section_title'>
                <span>Contact</span> Us
              </p>
              <p className='gnrl_para'>
                Have something fruitful in your mind? Let's connect and make
                your Idea a Reality..
              </p>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 wow animate__animated animate__fadeInRight'>
              {/* <img src="img/contact_us_illustrator.svg"> */}
              {/* <lottie-player
                src="https://assets6.lottiefiles.com/private_files/lf30_UWktQI.json"
                background="transparent"
                speed={1}
                style={{ width: "420px", height: "420px" }}
                loop
                autoPlay
              /> */}
              <ContactUsLottie />
            </div>
          </div>
        </div>
      </div>
      <div className='contact_us_02 mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='row'>
                <div
                  className='col-lg-12 wow animate__animated animate__fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='0.2s'
                >
                  <div className='contact_us_form'>
                    <p className='section_title'>
                      Let’s<span> talk</span>
                    </p>
                    <input
                      type='text'
                      placeholder='Name'
                      name='name'
                      onChange={(e) => handleInputChange(e)}
                      value={state.name}
                    />
                    <input
                      type='email'
                      placeholder='Email'
                      name='email'
                      onChange={(e) => handleInputChange(e)}
                      value={state.email}
                    />
                    <textarea
                      placeholder='Message'
                      name='message'
                      onChange={(e) => handleInputChange(e)}
                      value={state.message}
                    />
                    <button className='gnrl_red_btn mt-4'>Submit</button>
                  </div>
                </div>
                <div
                  className='col-lg-6 col-md-6 contact_us_form_btm mt-5 wow animate__animated animate__fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='0.2s'
                >
                  <p className='country'>India</p>
                  <p className='city'>Ahmedabad</p>
                  <p className='mail_phote'>
                    <a
                      href='mailto:info@saturncube.com'
                      style={{ color: '#5897e8' }}
                    >
                      info@saturncube.com
                    </a>
                    <br />
                    +91 91061 61391
                  </p>
                  <p className='gnrl_para'>
                    709 Swanik Arcade, Behind Vardan Tower, Shashtrinagar,
                    Naranpura, Ahmedabad - 380013, Gujarat, India
                  </p>
                </div>
                <div
                  className='col-lg-6 col-md-6 contact_us_form_btm mt-5 wow animate__animated animate__fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='0.2s'
                >
                  <p className='country'>Netherlands</p>
                  <p className='city'>Beusichem</p>
                  <p className='mail_phote'>
                    pim.meurs@saturncube.nl
                    <br />
                    +31-643-972-900
                  </p>
                  <p className='gnrl_para'>
                    Smalriemseweg 21, 4112 NA Beusichem,
                    <br />
                    Netherlands
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ContactUs;

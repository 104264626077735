import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  header1,
  srvcArrow,
  blackArrow,
  uiuxDtls1,
  pui1,
  pui2,
  pui3,
} from '../../AllImages/AllImages';

export default function UIUXDesign() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <section className='service_header'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 text-center wow animate__animated animate__fadeInLeft'>
              <img src={header1} alt='' />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 wow animate__animated animate__fadeInRight'>
              <p className='section_title'>
                <span>UI/UX </span>Design
              </p>
              <p className='gnrl_para'>
                Masterpieces are always crafted with undying passion, a dash of
                craziness and a lot of discipline.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Section - 2 */}
      <section
        className='service_header_arrow wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <img src={srvcArrow} alt='' />
            </div>
          </div>
        </div>
      </section>
      {/* section - 3 */}
      <section
        className='service_details_main wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-7 col-sm-12 service_details_txt text-left'>
              <p className='section_title'>
                Design a creative yet
                <br />
                <span>user-friendly platform!</span>
              </p>
              <p className='gnrl_para mt-3'>
                Uncovering the right problems to solve is at the heart of great
                UX and UI design. We build personalized user experiences that
                guide each visitor to their ideal product. UX design is less
                about a pretty design and more about delivering an intuitive
                experience to your customers. If Website or App is easy to use,
                it will do half job of marketing at that stage itself.
              </p>
            </div>
            <div className='col-lg-5 col-md-5 col-sm-12 service_details_img_right text-center'>
              <img src={uiuxDtls1} alt='' />
            </div>
          </div>
        </div>
      </section>
      {/* section - 4 */}
      {/* <section
        className='home_sec_08 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                Our Latest <span>Work</span>
              </p>
              <p className='gnrl_para mt-2'>
                Wondering how we have done so far? Take a look at our portfolio
                and see what we have delivered so far.
              </p>
            </div>
            <div className='col-lg-12 mt-5'>
              <div className='row'>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pui1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pui2} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pui3} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pui1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 text-center'>
                  <button className='gnrl_red_btn'>View all</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </Fragment>
  );
}

import React, { Fragment, useEffect } from "react";

import {
  header2,
  srvcArrow,
  blackArrow,
  pw1,
  pw2,
  pw3,
  pw4,
  wd1,
  wd2,
  wd3,
  wd4,
  wd5,
} from "../../AllImages/AllImages";

export default function WebDevelopment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <section className='service_header'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 text-center wow animate__animated animate__fadeInLeft'>
              <img src={header2} alt='' />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 wow animate__animated animate__fadeInRight'>
              <p className='section_title'>
                <span>Web </span>Development
              </p>
              <p className='gnrl_para'>
                Masterpieces are always crafted with undying passion, a dash of
                craziness and a lot of discipline.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className='service_header_arrow wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <img src={srvcArrow} alt='' />
            </div>
          </div>
        </div>
      </section>
      <section className='service_details_main'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-md-12 mb-5 wow animate__animated animate__fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='0.2s'
            >
              <div className='row'>
                <div className='col-lg-7 col-md-7 col-sm-12 service_details_txt text-left'>
                  <p className='section_title'>
                    <span>Enterprise </span>Solutions
                  </p>
                  <p className='gnrl_para mt-3'>
                    An excellent user experience defines the ease of your
                    digital presence. A good UI/UX leads users to interact with
                    you and provide feedback that usually result in product
                    improvement. This critical information you may only be able
                    to get from your users. That’s why it is necessary to have a
                    user-friendly UI/UX design.
                  </p>
                  <ul>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet you can write anu thing you
                      want.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                  </ul>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 service_details_img_right text-center'>
                  <img src={wd1} alt='' />
                </div>
              </div>
            </div>
            <div
              className='col-md-12 mt-5 mb-5 wow animate__animated animate__fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='0.2s'
            >
              <div className='row'>
                <div className='col-lg-5 col-md-5 col-sm-12 service_details_img_left text-center'>
                  <img src={wd2} alt='' />
                </div>
                <div className='col-lg-7 col-md-7 col-sm-12 service_details_txt text-left'>
                  <p className='section_title'>
                    <span>E-Commerce</span>
                  </p>
                  <p className='gnrl_para mt-3'>
                    An excellent user experience defines the ease of your
                    digital presence. A good UI/UX leads users to interact with
                    you and provide feedback that usually result in product
                    improvement. This critical information you may only be able
                    to get from your users. That’s why it is necessary to have a
                    user-friendly UI/UX design.
                  </p>
                  <ul>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet you can write anu thing you
                      want.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className='col-md-12 mt-5 mb-5 wow animate__animated animate__fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='0.2s'
            >
              <div className='row'>
                <div className='col-lg-7 col-md-7 col-sm-12 service_details_txt text-left'>
                  <p className='section_title'>
                    Mobile <span>Backend &amp; APIs</span>
                  </p>
                  <p className='gnrl_para mt-3'>
                    An excellent user experience defines the ease of your
                    digital presence. A good UI/UX leads users to interact with
                    you and provide feedback that usually result in product
                    improvement. This critical information you may only be able
                    to get from your users. That’s why it is necessary to have a
                    user-friendly UI/UX design.
                  </p>
                  <ul>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet you can write anu thing you
                      want.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                  </ul>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 service_details_img_right text-center'>
                  <img src={wd3} alt='' />
                </div>
              </div>
            </div>
            <div
              className='col-md-12 mt-5 mb-5 wow animate__animated animate__fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='0.2s'
            >
              <div className='row'>
                <div className='col-lg-5 col-md-5 col-sm-12 service_details_img_left text-center'>
                  <img src={wd4} alt='' />
                </div>
                <div className='col-lg-7 col-md-7 col-sm-12 service_details_txt text-left'>
                  <p className='section_title'>
                    <span>Content Management </span>System
                  </p>
                  <p className='gnrl_para mt-3'>
                    An excellent user experience defines the ease of your
                    digital presence. A good UI/UX leads users to interact with
                    you and provide feedback that usually result in product
                    improvement. This critical information you may only be able
                    to get from your users. That’s why it is necessary to have a
                    user-friendly UI/UX design.
                  </p>
                  <ul>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet you can write anu thing you
                      want.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className='col-md-12 mt-5 mb-5 wow animate__animated animate__fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='0.2s'
            >
              <div className='row'>
                <div className='col-lg-7 col-md-7 col-sm-12 service_details_txt text-left'>
                  <p className='section_title'>
                    Custom <span>Web Development</span>
                  </p>
                  <p className='gnrl_para mt-3'>
                    An excellent user experience defines the ease of your
                    digital presence. A good UI/UX leads users to interact with
                    you and provide feedback that usually result in product
                    improvement. This critical information you may only be able
                    to get from your users. That’s why it is necessary to have a
                    user-friendly UI/UX design.
                  </p>
                  <ul>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet you can write anu thing you
                      want.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                  </ul>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 service_details_img_right text-center'>
                  <img src={wd5} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className='home_sec_08 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                Our Latest <span>Work</span>
              </p>
              <p className='gnrl_para mt-2'>
                Wondering how we have done so far? Take a look at our portfolio
                and see what we have delivered so far.
              </p>
            </div>
            <div className='col-lg-12 mt-5'>
              <div className='row'>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Landrover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pw1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Stident HOB
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pw2} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Writing web
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pw3} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          TGVIST
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pw4} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 text-center'>
                  <button className='gnrl_red_btn'>View all</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

import React, { Fragment } from 'react';

import {
  header1,
  blackArrow,
  srvcArrow,
  ad1,
  ad2,
  ad3,
  ad4,
  pa1,
  pa2,
  pa3,
} from '../../AllImages/AllImages';

const AppDevelopment = () => {
  return (
    <Fragment>
      <section className='service_header'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 text-center wow animate__animated animate__fadeInLeft'>
              <img src={header1} alt='' />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 wow animate__animated animate__fadeInRight'>
              <p className='section_title'>
                <span>Mobile App </span>Development
              </p>
              <p className='gnrl_para'>
                Masterpieces are always crafted with undying passion, a dash of
                craziness and a lot of discipline.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className='service_header_arrow wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <img src={srvcArrow} alt='' />
            </div>
          </div>
        </div>
      </section>
      <section className='service_details_main'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-md-12 mb-5 wow animate__animated animate__fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='0.2s'
            >
              <div className='row'>
                <div className='col-lg-7 col-md-7 col-sm-12 service_details_txt text-left'>
                  <p className='section_title'>
                    <span>Fultter</span> App Development
                  </p>
                  <p className='gnrl_para mt-3'>
                    Our team is accomplished in developing the Flutter apps, we
                    are knowledge-able about the specific set of business
                    requirements, policies, rules, and so on. Our team is highly
                    skilled in using the required tools in developing the
                    flawless Enterprise app accommodating all the unique needs.
                  </p>
                  {/* <ul>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet you can write anu thing you
                      want.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                  </ul> */}
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 service_details_img_right text-center'>
                  <img src={ad1} alt='' />
                </div>
              </div>
            </div>
            <div
              className='col-md-12 mt-5 mb-5 wow animate__animated animate__fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='0.2s'
            >
              <div className='row'>
                <div className='col-lg-5 col-md-5 col-sm-12 service_details_img_left text-center'>
                  <img src={ad2} alt='' />
                </div>
                <div className='col-lg-7 col-md-7 col-sm-12 service_details_txt text-left'>
                  <p className='section_title'>
                    <span>iOS</span> App Development
                  </p>
                  <p className='gnrl_para mt-3'>
                    Meet our experts who can understand your business idea and
                    build an iOS application that will keep you ahead of the
                    competition in the market. Unlock the full potential of your
                    business by effectively connecting with your customers using
                    the most advanced iOS application development platform. We
                    have all suitable infrastructure including iMac, Macmini,
                    iPhone, iPad, Watch to carry out development and testing on
                    real latest devices.
                  </p>
                </div>
              </div>
            </div>
            <div
              className='col-md-12 mt-5 mb-5 wow animate__animated animate__fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='0.2s'
            >
              <div className='row'>
                <div className='col-lg-7 col-md-7 col-sm-12 service_details_txt text-left'>
                  <p className='section_title'>
                    <span>Android</span> App Development
                  </p>
                  <p className='gnrl_para mt-3'>
                    We build reliable, reliant and fast-loading native apps,
                    compliant to your business needs and engage your users
                    effectively. We’ll help you accelerate your business success
                    with attractive, engaging and budget-friendly native android
                    mobile apps with customized services.
                  </p>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 service_details_img_right text-center'>
                  <img src={ad3} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className='home_sec_08 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                Our Latest <span>Work</span>
              </p>
              <p className='gnrl_para mt-2'>
                Wondering how we have done so far? Take a look at our portfolio
                and see what we have delivered so far.
              </p>
            </div>
            <div className='col-lg-12 mt-5'>
              <div className='row'>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pa1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pa2} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pa3} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pa1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 text-center'>
                  <button className='gnrl_red_btn'>View all</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </Fragment>
  );
};
export default AppDevelopment;

import React, { Fragment, useEffect } from "react";
import celebration from "../../assets/img/celebration_illustration.svg";
import event1 from "../../assets/img/event/img_01.jpg";
import event2 from "../../assets/img/event/img_02.jpg";
import event3 from "../../assets/img/event/img_03.jpg";
import event4 from "../../assets/img/event/img_04.jpg";
import blackArrow from "../../assets/img/button_black_arrow.svg";
import btnArrWhile from "../../assets/img/button_white_arrow.svg";
import { Link } from "react-router-dom";

const Event = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {/* section-1 */}
      <section className="event_01 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInLeft">
              <p className="section_title">
                <span>Celebration </span>Time
              </p>
              <p className="gnrl_para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInRight">
              <img src={celebration} alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* section-2 */}
      <section className="event_02 mt-5">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12 mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-6">
                  <div className="event_02_left_img">
                    <img src={event1} alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-6">
                  <div className="event_02_left_content">
                    <p className="section_title mt-2">Event title</p>
                    <p className="gnrl_para mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p>
                    <Link to="event-details">
                      <button className="gnrl_black_brder_btn mt-2">
                        View more
                        <img src={blackArrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-12 mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-6">
                  <div className="event_02_right_content">
                    <p className="section_title mt-2">Event title</p>
                    <p className="gnrl_para mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p>
                    <Link to="event-details">
                      <button className="gnrl_black_brder_btn mt-2">
                        View more
                        <img src={blackArrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-6">
                  <div className="event_02_right_img">
                    <img src={event2} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-12 mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-6">
                  <div className="event_02_left_img">
                    <img src={event3} alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-6">
                  <div className="event_02_left_content">
                    <p className="section_title mt-2">Event title</p>
                    <p className="gnrl_para mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p>
                    <Link to="event-details">
                      <button className="gnrl_black_brder_btn mt-2">
                        View more
                        <img src={blackArrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-12 mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-6">
                  <div className="event_02_right_content">
                    <p className="section_title mt-2">Event title</p>
                    <p className="gnrl_para mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p>
                    <Link to="event-details">
                      <button className="gnrl_black_brder_btn mt-2">
                        View more
                        <img src={blackArrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-6">
                  <div className="event_02_right_img">
                    <img src={event4} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section-3 */}
      <section
        className="get_free_quot_sec mt-5 pb-2 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <p className="section_title">
                Ask for a <span>Proposal</span> – fill out simple form and we
                will get back to you
              </p>
            </div>
            <div className="col-lg-3 col-md-12 text-right">
              <button
                className="gnrl_red_btn"
                data-toggle="modal"
                data-target="#get_free_qout"
              >
                Get free quote
                <img src={btnArrWhile} alt="" />
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* The Modal */}
      <div className="modal" id="get_free_qout">
        <div className="modal-dialog">
          <div className="modal-content p-2">
            {/* Modal Header */}
            <div className="modal-header w-100 float-left border-0 pb-0">
              <p className="section_title">
                Inquiry <span>Form</span>
              </p>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body w-100 float-left">
              <div className="apply_for_job">
                <input type="text" placeholder="Your Name" />
                <input type="email" placeholder="Your Email" />
                <textarea placeholder="Your Message" defaultValue={""} />
                <button className="gnrl_red_btn mt-4">Submit</button>
              </div>
            </div>
            {/* Modal footer */}
            {/* <div class="modal-footer w-100 float-left">
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Event;

import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  whoWeAre,
  whatWeDo,
  aboutUsIllu,
  square,
  testimoinial1,
  whiteArrow,
  testimoinial2,
} from '../../AllImages/AllImages';
// import $ from "jquery";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    class Defilee {
      constructor(element) {
        if (!element) {
          return;
        }
        this.element = element;
        this._name = 'defilee';
        this._itemSelector = '.' + this._itemClass;
      }
      init() {
        this.addLoop();
      }
      addLoop() {
        const parent = this.element;
        Array.prototype.slice
          .call(this.element.children)
          .reverse()
          .forEach((el) => {
            const clone = el.cloneNode(true);
            clone.classList.add('clone');
            parent.insertBefore(clone, parent.firstChild);
          });
      }
    }

    const defilee = new Defilee(document.getElementById('defilee'));
    defilee.init();
  }, []);

  const [openImage, setopenImage] = useState(false);
  const [photoIndex, setphotoIndex] = useState(4);

  const imageData = [
    'https://content3.jdmagicbox.com/comp/pune/g1/020pxx20.xx20.100216092855.h7g1/catalogue/aarya-design-sadashiv-peth-pune-interior-designers-mzkchtgr8o.jpg',
    'https://rimage.gnst.jp/rest/img/4pkmex790000/s_0n7n.jpg?t=1581998004',
    'https://i.pinimg.com/originals/2b/38/ef/2b38ef642c97a357cd86880b01ffec28.jpg',
    'https://i.pinimg.com/originals/0a/8f/7f/0a8f7f1318aa651f5db338a572652cce.jpg',
    'https://img.pr.com/release/2005/457205/pressrelease_original_457205_1590511784.jpeg',
    'https://www.easyoffices.com/blog/wp-content/uploads/2020/05/EO-3.jpg',
    'https://www.surfacequest.com/wp-content/uploads/2016/10/7700-France-512.jpg',
    'https://content3.jdmagicbox.com/comp/pune/g1/020pxx20.xx20.100216092855.h7g1/catalogue/aarya-design-sadashiv-peth-pune-interior-designers-f7nhd3qfne.jpg',
    'https://lh3.googleusercontent.com/-isPoBF8y3--KfTzwbP4tRkBhZ_5CeyO1UCN3_-1ZGEMXEq27GUniG6UHXRxWfwGE-8',
    'https://i.pinimg.com/originals/0a/8f/7f/0a8f7f1318aa651f5db338a572652cce.jpg',
    'https://lh3.googleusercontent.com/25779m6oW0Rdrjy7ey52663x6kjsYAQxPwNC2ORYyijCNht0Ys9nvGR6YNgLTqOxHDs',
    'https://www.easyoffices.com/blog/wp-content/uploads/2020/05/EO-2.jpg',
    'https://img.pr.com/release/2005/457205/pressrelease_original_457205_1590511784.jpeg',
    'https://rimage.gnst.jp/rest/img/4pkmex790000/s_0n7n.jpg?t=1581998004',
    'https://img.pr.com/release/2005/457205/pressrelease_original_457205_1590511784.jpeg',
    'https://content3.jdmagicbox.com/comp/pune/g1/020pxx20.xx20.100216092855.h7g1/catalogue/aarya-design-sadashiv-peth-pune-interior-designers-f7nhd3qfne.jpg',
    'https://lh3.googleusercontent.com/25779m6oW0Rdrjy7ey52663x6kjsYAQxPwNC2ORYyijCNht0Ys9nvGR6YNgLTqOxHDs',
    'https://img.pr.com/release/2005/457205/pressrelease_original_457205_1590511784.jpeg',
  ];
  const imgModal = (img) => {
    const index = imageData.indexOf(img);

    setopenImage(!openImage);
    setphotoIndex(index);
  };

  const items = [
    {
      src: testimoinial2,
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development this for what our customer say about ys is a dunny text here sinc 1863 industry. Lorem Ipsum.',
    },
    {
      src: testimoinial1,
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development this for what our customer say about ys is a dunny text here sinc 1863 industry. Lorem Ipsum.',
    },
    {
      src: testimoinial1,
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development this for what our customer say about ys is a dunny text here sinc 1863 industry. Lorem Ipsum.',
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className='carousel-item active boat'>
          <div className='d-md-block'>
            <div className='row align-items-center'>
              <div
                className='col-12 col-md-12 col-lg-12 col-xl-12'
                style={{ paddingBottom: '62px' }}
              >
                <div className='caption animated fadeIn'>
                  <div
                    className='row'
                    style={{
                      paddingLeft: '40px',
                      paddingRight: '40px',
                      paddingTop: '40px',
                    }}
                  >
                    <div className='col-lg-6 col-md-6'>
                      <div className='about_sec02_img'>
                        <img src={item.src} alt='' />
                        <img className='doted_squere' src={square} alt='' />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 text-left'>
                      <p className='mediam_txt w-100 float-left'>
                        Passive Lane
                      </p>
                      <p className='gnrl_para mt-2'>{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        /> */}
      </CarouselItem>
    );
  });
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   centerMode: true,
  // };

  return (
    <Fragment>
      <div
        className='about_sec01 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <img src={aboutUsIllu} alt='' />
            </div>
            <div className='col-lg-12 text-center mt-4'>
              <p className='section_title'>
                Our Treasure Comprises Our <br />
                <span>People, Vision &amp; Values</span>
              </p>
            </div>
            <div className='col-lg-10 text-center mt-3'>
              <p className='gnrl_para mt-2'>
                <span>SaturnCube Technologies</span> is the largest and top
                leading Design, Web and Mobile development company, we are
                working on all major different types of CMS development, Custom
                PHP development, develop a Responsive Web Design and also
                editing with existing web design and make a responsive
                environment, and we having also expertise in mobile development.
              </p>
              <p className='gnrl_para mt-2'>
                We are having a smart and very strong potential team member for
                making the whole execution fast and as per client requirement.
                Our main goal is the <span>client satisfaction</span> which
                increases our company standard.
              </p>
              <p className='gnrl_para mt-2'>
                If you have any kind of requirement at any stage, feel free to
                drop us call or message, our representative would get back to
                you within few hours and at max 24 hours.
              </p>
            </div>
          </div>
        </div>
        <img className='doted_squere' src={square} alt='' />
      </div>
      <section
        className='about_sec02 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                What our <span>customers</span> say
              </p>
              <p className='gnrl_para mt-2'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a<br />
                type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting,
              </p>
            </div>
            <div className='col-lg-12'>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                  direction='prev'
                  directionText='Previous'
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction='next'
                  directionText='Next'
                  onClickHandler={next}
                />
              </Carousel>
              {/* <div
                id="carousel"
                className="carousel slide hero-slides"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    className="active"
                    data-target="#carousel"
                    data-slide-to={0}
                  />
                  <li data-target="#carousel" data-slide-to={1} />
                  <li data-target="#carousel" data-slide-to={2} />
                </ol>
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active boat">
                    <div className="d-md-block">
                      <div className="row align-items-center">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="caption animated fadeIn">
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="about_sec02_img">
                                  <img src={testimoinial1} alt="" />
                                  <img
                                    className="doted_squere"
                                    src={square}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 text-left">
                                <p className="mediam_txt w-100 float-left">
                                  Passive Lane
                                </p>
                                <p className="gnrl_para mt-2">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and web development typesetting this
                                  is app development dunny text here sinc 1863
                                  industry. Lorem Ipsum. Lorem Ipsum is simply
                                  dummy text of the printing and web development
                                  typesetting this is app development this for
                                  what our customer say about ys is a dunny text
                                  here sinc 1863 industry. Lorem Ipsum.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item sea">
                    <div className="d-md-block">
                      <div className="row align-items-center h-100">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="caption animated fadeIn">
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="about_sec02_img">
                                  <img src={testimoinial1} alt="" />
                                  <img
                                    className="doted_squere"
                                    src={square}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 text-left">
                                <p className="mediam_txt w-100 float-left">
                                  Passive Lane
                                </p>
                                <p className="gnrl_para mt-2">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and web development typesetting this
                                  is app development dunny text here sinc 1863
                                  industry. Lorem Ipsum. Lorem Ipsum is simply
                                  dummy text of the printing and web development
                                  typesetting this is app development this for
                                  what our customer say about ys is a dunny text
                                  here sinc 1863 industry. Lorem Ipsum.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item river">
                    <div className="d-md-block">
                      <div className="row align-items-center h-100">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="caption animated fadeIn">
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="about_sec02_img">
                                  <img src={testimoinial1} alt="" />
                                  <img
                                    className="doted_squere"
                                    src={square}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 text-left">
                                <p className="mediam_txt w-100 float-left">
                                  Passive Lane
                                </p>
                                <p className="gnrl_para mt-2">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and web development typesetting this
                                  is app development dunny text here sinc 1863
                                  industry. Lorem Ipsum. Lorem Ipsum is simply
                                  dummy text of the printing and web development
                                  typesetting this is app development this for
                                  what our customer say about ys is a dunny text
                                  here sinc 1863 industry. Lorem Ipsum.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carousel"
                  role="button"
                  data-slide="prev"
                >
                  <span aria-hidden="true">
                    <img src={proPrevious} alt="" />
                  </span>
                  <span className="sr-only" />
                </a>
                <a
                  className="carousel-control-next"
                  href="#carousel"
                  role="button"
                  data-slide="next"
                >
                  <span aria-hidden="true">
                    <img src={proNext} alt="" />
                  </span>
                  <span className="sr-only" />
                </a>
              </div>*/}
            </div>
          </div>
        </div>
      </section>
      <section
        className='about_sec03 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-8 col-md-8'>
                  <p className='section_title w-100 float-left text-left'>
                    Who We Are
                  </p>
                  <p className='gnrl_para w-100 float-left text-left mt-3'>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </p>
                  <p className='gnrl_para w-100 float-left text-left'>
                    It was popularised in the 1960s with the release of Letraset
                    sheets containing Lorem Ipsum passages, and more recently
                    with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </p>
                </div>
                <div className='col-lg-4 col-md-6 text-center'>
                  <img src={whoWeAre} alt='' />
                </div>
              </div>
            </div>
            <div className='col-lg-12 mt-5'>
              <div className='row'>
                <div className='col-lg-4 col-md-6 text-center'>
                  <img src={whatWeDo} alt='' />
                </div>
                <div className='col-lg-8 col-md-8'>
                  <p className='section_title w-100 float-left text-right'>
                    What We Do
                  </p>
                  <p className='gnrl_para w-100 float-left text-right mt-3'>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </p>
                  <p className='gnrl_para w-100 float-left text-right'>
                    It was popularised in the 1960s with the release of Letraset
                    sheets containing Lorem Ipsum passages, and more recently
                    with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className='about_sec04 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-4 text-center'>
              <p className='section_title w-100 float-left text-center'>560+</p>
              <p className='gnrl_para w-100 float-left text-center'>
                Satisfied Clients Across the Globe
              </p>
            </div>
            <div className='col-lg-4 col-md-4 text-center'>
              <p className='section_title w-100 float-left text-center'>
                1500+
              </p>
              <p className='gnrl_para w-100 float-left text-center'>
                Projects Delivered Successfully
              </p>
            </div>
            <div className='col-lg-4 col-md-4 text-center'>
              <p className='section_title w-100 float-left text-center'>30+</p>
              <p className='gnrl_para w-100 float-left text-center'>
                Experts Under the Same Roof
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className='about_sec05 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                <span>The extended team</span> that is
                <br />
                part of your team
              </p>
              <p className='gnrl_para mt-2'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a<br />
                type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting,
              </p>
            </div>
            <div className='col-lg-12 mt-3 mb-3'>
              <div className='defilee__outer'>
                <section id='defilee' className='defilee__ctnr'>
                  <div className='defilee__div defilee__div--special2'>
                    <img
                      src='https://upload.wikimedia.org/wikipedia/commons/e/e9/Albert-einstein-profile-picture-512x512.png.cf.png'
                      alt='Avatar for user 1'
                    />
                  </div>
                  <div className='defilee__div defilee__div--special'>
                    <img
                      src='https://cdn.wallpapersafari.com/43/6/2UBOHV.jpg'
                      alt='Avatar for user 2'
                    />
                  </div>
                  <div className='defilee__div defilee__div--special1 first'>
                    <img
                      src='https://i.pinimg.com/originals/d5/bd/4e/d5bd4e9896c93290cbe2a8cbdf7228dd.jpg'
                      alt='Avatar for user 3'
                    />
                  </div>
                  <div className='defilee__div defilee__div--special3'>
                    <img
                      src='https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSh-hDSvz0dCgxZSeUc-PL6NLRNIEy68ZUNbA&usqp=CAU'
                      alt='Avatar for user 4'
                    />
                  </div>
                  <div className='defilee__div defilee__div--special4'>
                    <img
                      src='https://snappygoat.com/b/4fe62c38a715c519dfc3d4be28fea8085b2a0c72'
                      alt='Avatar for user 5'
                    />
                  </div>
                  <div className='defilee__div defilee__div--special1'>
                    <img
                      src='https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSh-hDSvz0dCgxZSeUc-PL6NLRNIEy68ZUNbA&usqp=CAU'
                      alt='Avatar for user 6'
                    />
                  </div>
                  <div className='defilee__div'>
                    <img
                      src='https://avatarfiles.alphacoders.com/561/56111.jpg'
                      alt='Avatar for user 7'
                    />
                  </div>
                  <div className='defilee__div defilee__div--special3'>
                    <img
                      src='https://codepen-chriscoyier-bucket.imgix.net/9.png'
                      alt='Avatar for user 8'
                    />
                  </div>
                  <div className='defilee__div defilee__div--special1'>
                    <img
                      src='https://codepen-chriscoyier-bucket.imgix.net/10.svg'
                      alt='Avatar for user 9'
                    />
                  </div>
                  <img className='doted_squere' src={square} alt='' />
                </section>
              </div>
            </div>
            <div className='col-lg-12 text-center mt-1'>
              <Link to='/career'>
                <button className='gnrl_red_btn'>
                  Join our team
                  <img src={whiteArrow} alt='' />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section
        className='get_free_quot_sec mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-9 col-md-12'>
              <p className='section_title'>
                Ask for a <span>Proposal</span> – fill out simple form and we
                will get back to you
              </p>
            </div>
            <div className='col-lg-3 col-md-12 text-right'>
              <button
                className='gnrl_red_btn'
                data-toggle='modal'
                data-target='#get_free_qout'
              >
                Get free quote
                <img src={whiteArrow} alt='' />
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* The Modal */}
      <div className='modal' id='get_free_qout'>
        <div className='modal-dialog'>
          <div className='modal-content p-2'>
            {/* Modal Header */}
            <div className='modal-header w-100 float-left border-0 pb-0'>
              <p className='section_title'>
                Inquiry <span>Form</span>
              </p>
              <button type='button' className='close' data-dismiss='modal'>
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className='modal-body w-100 float-left'>
              <div className='apply_for_job'>
                <input type='text' placeholder='Your Name' />
                <input type='email' placeholder='Your Email' />
                <textarea placeholder='Your Message' defaultValue={''} />
                <button className='gnrl_red_btn mt-4'>Submit</button>
              </div>
            </div>
            {/* Modal footer */}
            {/* <div class="modal-footer w-100 float-left">
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div> */}
          </div>
        </div>
      </div>
      <section
        className='about_sec06 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                Our <span>work place</span>
              </p>
              <p className='gnrl_para mt-2'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make.
              </p>
            </div>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='gallery'>
                  {/* first Row start */}
                  <div className='float-left w-100'>
                    {imageData.slice(0, 4).map((image, i) => (
                      <div
                        key={i}
                        className='cutm_wid_25 cstm_he_wi'
                        onClick={(e) => imgModal(image)}
                      >
                        <figure className='figure'>
                          <img src={image} alt='' />
                          <figcaption>
                            Daytona Beach <small>United States</small>
                          </figcaption>
                        </figure>
                      </div>
                    ))}
                  </div>
                  {/* first Row end */}
                  {/* Second Row start */}
                  <div className='cutm_wid_50 cstm_he_wi'>
                    <div className='float-left w-100'>
                      {/* 1st part (4 images) start */}
                      <div className='cutm_wid_50 cstm_he_wi'>
                        {imageData.slice(4, 8).map((image, i) => (
                          <div
                            key={i}
                            className='cutm_wid_50 cstm_he_wi'
                            onClick={(e) => imgModal(image)}
                          >
                            <figure className='figure'>
                              <img src={image} alt='' />
                              <figcaption>
                                Daytona Beach <small>United States</small>
                              </figcaption>
                            </figure>
                          </div>
                        ))}
                      </div>
                      {/* 1st part (4 images) stop */}

                      {/* 2nd part (1 images) start */}
                      {imageData.slice(9, 10).map((image, i) => (
                        <div
                          key={i}
                          className='cutm_wid_50 cstm_he_wi'
                          onClick={(e) => imgModal(image)}
                        >
                          <figure className='figure'>
                            <img src={image} alt='' />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                      ))}
                    </div>
                    {/* 2nd part (1 images) end */}
                    {/* Second Row start */}

                    {/* Third Row start */}

                    <div className='float-left w-100'>
                      {/* 3rd part (1 images left) start */}
                      {imageData.slice(11, 12).map((image, i) => (
                        <div
                          key={i}
                          className='cutm_wid_50 cstm_he_wi'
                          onClick={(e) => imgModal(image)}
                        >
                          <figure className='figure'>
                            <img src={image} alt='' />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                      ))}

                      {/* 3rd part (1 images left) end */}

                      {/* 3rd part (4 images ) start */}

                      <div className='cutm_wid_50 cstm_he_wi'>
                        {imageData.slice(13, 17).map((image, i) => (
                          <div
                            key={i}
                            className='cutm_wid_50 cstm_he_wi'
                            onClick={(e) => imgModal(image)}
                          >
                            <figure className='figure'>
                              <img src={image} alt='' />
                              <figcaption>
                                Daytona Beach <small>United States</small>
                              </figcaption>
                            </figure>
                          </div>
                        ))}
                      </div>
                      {/* 3rd part (4 images ) start */}
                    </div>
                  </div>
                  {/* Third Row end*/}
                  {/* big Image start */}
                  {imageData.slice(17, 18).map((image, i) => (
                    <div
                      key={i}
                      className='cutm_wid_50 cstm_he_wi'
                      onClick={(e) => imgModal(image)}
                    >
                      <figure className='figure'>
                        <img src={image} alt='' />
                        <figcaption>
                          Daytona Beach <small>United States</small>
                        </figcaption>
                      </figure>
                    </div>
                  ))}
                  {/* big Image end */}
                </div>
                {openImage && (
                  <Lightbox
                    mainSrc={imageData[photoIndex]}
                    nextSrc={imageData[(photoIndex + 1) % imageData.length]}
                    prevSrc={
                      imageData[
                        (photoIndex + imageData.length - 1) % imageData.length
                      ]
                    }
                    onCloseRequest={() => {
                      setopenImage(false);
                      // setphotoIndex(
                      //   (photoIndex + imageData.length - 1) % imageData.length
                      // );
                    }}
                    onMovePrevRequest={() =>
                      setphotoIndex(
                        (photoIndex + imageData.length - 1) % imageData.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setphotoIndex((photoIndex + 1) % imageData.length)
                    }
                  />
                )}
                {/* <div className="gallery">
                  <div className="float-left w-100">
                    <div className="cutm_wid_25 cstm_he_wi">
                      <figure className="figure">
                        <img
                          src="https://i.pinimg.com/originals/0a/8f/7f/0a8f7f1318aa651f5db338a572652cce.jpg"
                          alt=""
                        />
                        <figcaption>
                          Daytona Beach <small>United States</small>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="cutm_wid_25 cstm_he_wi">
                      <figure className="figure">
                        <img
                          src="https://content3.jdmagicbox.com/comp/pune/g1/020pxx20.xx20.100216092855.h7g1/catalogue/aarya-design-sadashiv-peth-pune-interior-designers-mzkchtgr8o.jpg"
                          alt=""
                        />
                        <figcaption>
                          Daytona Beach <small>United States</small>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="cutm_wid_25 cstm_he_wi">
                      <figure className="figure">
                        <img
                          src="https://rimage.gnst.jp/rest/img/4pkmex790000/s_0n7n.jpg?t=1581998004"
                          alt=""
                        />
                        <figcaption>
                          Daytona Beach <small>United States</small>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="cutm_wid_25 cstm_he_wi">
                      <figure className="figure">
                        <img
                          src="https://i.pinimg.com/originals/2b/38/ef/2b38ef642c97a357cd86880b01ffec28.jpg"
                          alt=""
                        />
                        <figcaption>
                          Daytona Beach <small>United States</small>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                  <div className="cutm_wid_50 cstm_he_wi">
                    <div className="float-left w-100">
                      <div className="cutm_wid_50 cstm_he_wi">
                        <div className="cutm_wid_50 cstm_he_wi">
                          <figure className="figure">
                            <img
                              src="https://i.pinimg.com/originals/0a/8f/7f/0a8f7f1318aa651f5db338a572652cce.jpg"
                              alt=""
                            />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cutm_wid_50 cstm_he_wi">
                          <figure className="figure">
                            <img
                              src="https://www.easyoffices.com/blog/wp-content/uploads/2020/05/EO-3.jpg"
                              alt=""
                            />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cutm_wid_50 cstm_he_wi">
                          <figure className="figure">
                            <img
                              src="https://www.surfacequest.com/wp-content/uploads/2016/10/7700-France-512.jpg"
                              alt=""
                            />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cutm_wid_50 cstm_he_wi">
                          <figure className="figure">
                            <img
                              src="https://content3.jdmagicbox.com/comp/pune/g1/020pxx20.xx20.100216092855.h7g1/catalogue/aarya-design-sadashiv-peth-pune-interior-designers-f7nhd3qfne.jpg"
                              alt=""
                            />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                      <div className="cutm_wid_50 cstm_he_wi">
                        <figure className="figure">
                          <img
                            src="https://www.usm.com/media/thumbs/images/make-it-your-workspace-page-56.jpg.512x512_q90_box-583%2C0%2C2916%2C2333_crop.jpg"
                            alt=""
                          />
                          <figcaption>
                            Daytona Beach <small>United States</small>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                    <div className="float-left w-100">
                      <div className="cutm_wid_50 cstm_he_wi">
                        <figure className="figure">
                          <img
                            src="https://www.usm.com/media/thumbs/images/make-it-your-workspace-page-12.jpg.512x512_q90_box-0%2C0%2C2333%2C2333_crop.jpg"
                            alt=""
                          />
                          <figcaption>
                            Daytona Beach <small>United States</small>
                          </figcaption>
                        </figure>
                      </div>
                      <div className="cutm_wid_50 cstm_he_wi">
                        <div className="cutm_wid_50 cstm_he_wi">
                          <figure className="figure">
                            <img
                              src="https://lh3.googleusercontent.com/-isPoBF8y3--KfTzwbP4tRkBhZ_5CeyO1UCN3_-1ZGEMXEq27GUniG6UHXRxWfwGE-8"
                              alt=""
                            />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cutm_wid_50 cstm_he_wi">
                          <figure className="figure">
                            <img
                              src="https://lh3.googleusercontent.com/25779m6oW0Rdrjy7ey52663x6kjsYAQxPwNC2ORYyijCNht0Ys9nvGR6YNgLTqOxHDs"
                              alt=""
                            />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cutm_wid_50 cstm_he_wi">
                          <figure className="figure">
                            <img
                              src="https://www.easyoffices.com/blog/wp-content/uploads/2020/05/EO-2.jpg"
                              alt=""
                            />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cutm_wid_50 cstm_he_wi">
                          <figure className="figure">
                            <img
                              src="https://media.glassdoor.com/l/139697/framestore-office.jpg"
                              alt=""
                            />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cutm_wid_50 cstm_he_wi">
                    <figure className="figure">
                      <img
                        src="https://img.pr.com/release/2005/457205/pressrelease_original_457205_1590511784.jpeg"
                        alt=""
                      />
                      <figcaption>
                        Daytona Beach <small>United States</small>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default AboutUs;

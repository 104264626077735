import React, { Fragment, useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.css";
import "./assets/css/responsive.css";
import "./assets/css/style.css";
import "./assets/css/testimonial_slider.css";
import "./assets/css/vivify.min.css";
// import "./assets/js/custome.js";
// import "./assets/js/bootstrap.min.js";
// import "./assets/js/jquery-3.2.1.slim.min.js";
// import "./assets/js/popper.min.js";
// import "./assets/js/wow.min.js";
// import $ from "jquery";
import WOW from "wow.js";
import AppRoutes from "./AppRoutes/AppRoutes";

function App() {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  return (
    <Fragment>
      <AppRoutes />
    </Fragment>
  );
}

export default App;

import React, { Fragment, useEffect } from "react";
import { srvcArrow, header5, seo2, seo1 } from "../../AllImages/AllImages";

const SEO = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <section className="service_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 text-center wow animate__animated animate__fadeInLeft">
              <img src={header5} alt="" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 wow animate__animated animate__fadeInRight">
              <p className="section_title">
                <span>SEO</span>
              </p>
              <p className="gnrl_para">
                Masterpieces are always crafted with undying passion, a dash of
                craziness and a lot of discipline.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="service_header_arrow wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <img src={srvcArrow} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="service_details_main">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 mb-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 service_details_txt text-left">
                  <p className="section_title">
                    <span>SEO</span> Service
                  </p>
                  <p className="gnrl_para mt-3">
                    An excellent user experience defines the ease of your
                    digital presence. A good UI/UX leads users to interact with
                    you and provide feedback that usually result in product
                    improvement. This critical information you may only be able
                    to get from your users. That’s why it is necessary to have a
                    user-friendly UI/UX design.
                  </p>
                  <ul>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet you can write anu thing you
                      want.
                    </li>
                    <li>
                      Lorem ipsum doelr sit amet this is a dummy tec here.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 service_details_img_right text-center">
                  <img src={seo1} alt="" />
                </div>
              </div>
            </div>
            <div
              className="col-md-12 mt-5 mb-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12 service_details_img_left text-center">
                  <img src={seo2} alt="" />
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 service_details_txt text-left">
                  <p className="section_title">
                    Why Is <span>Search Engine Optimization </span>Service Is
                    Important For Your Business?
                  </p>
                  <p className="gnrl_para mt-3">
                    An excellent user experience defines the ease of your
                    digital presence. A good UI/UX leads users to interact with
                    you and provide feedback that usually result in product
                    improvement. This critical information you may only be able
                    to get from your users. That’s why it is necessary to have a
                    user-friendly UI/UX design.
                  </p>
                  <p className="gnrl_para">
                    An excellent user experience defines the ease of your
                    digital presence. A good UI/UX leads users to interact with
                    you and provide feedback that usually result in product
                    improvement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about_sec04 mt-5 pb-0 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 text-center">
              <p className="section_title w-100 float-left text-center">560+</p>
              <p className="gnrl_para w-100 float-left text-center">
                Satisfied Clients Across the Globe
              </p>
            </div>
            <div className="col-lg-4 col-md-4 text-center">
              <p className="section_title w-100 float-left text-center">
                1500+
              </p>
              <p className="gnrl_para w-100 float-left text-center">
                Projects Delivered Successfully
              </p>
            </div>
            <div className="col-lg-4 col-md-4 text-center">
              <p className="section_title w-100 float-left text-center">30+</p>
              <p className="gnrl_para w-100 float-left text-center">
                Experts Under the Same Roof
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default SEO;

import React, { Fragment, useEffect, useState } from 'react';
import logo from '../../assets/img/SC_logo.svg';
import contactUs from '../../assets/img/button_white_arrow.svg';
import drop from '../../assets/img/drop_icon.svg';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const DefaultHeader = () => {
  useEffect(() => {});

  const [displayClass, setdisplayClass] = useState('none');
  const [openSettings, setopenSettings] = useState(true);
  const [demo, setdemo] = useState(false);
  const openNavbar = (e) => {
    setdisplayClass('block');
    setdemo(false);
  };
  const closeNavbar = (e) => {
    setdemo(true);
    setTimeout(() => {
      setdisplayClass('none');
    }, 1000);
  };

  const toggleServicesMenu = (e) => {
    setopenSettings(!openSettings);
  };
  return (
    <Fragment>
      <header>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-2 col-md-12 col-sm-4 col-8 main_logo'>
              <Link to='/'>
                <img src={logo} alt='' />
              </Link>
            </div>
            <div className='col-lg-10 col-md-12 col-sm-12'>
              <nav className='desktop_menu'>
                <ul>
                  <li>
                    <Link id='cudtn_mnu' to='/'>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      id='cudtn_mnu'
                      //  to='/about-us'
                      to='#'
                    >
                      About Us
                    </Link>
                  </li>
                  <li className='custm_drop'>
                    <Link id='cudtn_mnu' to='#'>
                      Services
                    </Link>
                    <ul className='drop_down'>
                      <Link to='/uiux-design' className='w-100 p-0 text-dark'>
                        <li className='hver_01'>UI UX Design</li>
                      </Link>
                      <Link
                        to='#'
                        className='w-100 p-0 text-dark'
                        style={{ cursor: 'initial' }}
                      >
                        <li className='hver_02'>
                          {/* <Link to='/web-development'> */}
                          Web Development
                        </li>
                      </Link>
                      <Link
                        to='/app-development'
                        className='w-100 p-0 text-dark'
                      >
                        <li className='hver_03'>Mobile App Development</li>
                      </Link>
                      <Link
                        to='#'
                        className='w-100 p-0 text-dark'
                        style={{ cursor: 'initial' }}
                      >
                        <li className='hver_04'>
                          {/* <Link to='/graphics-design'> */}
                          Graphics Design
                        </li>
                      </Link>
                      <Link
                        to='#'
                        className='w-100 p-0 text-dark'
                        style={{ cursor: 'initial' }}
                      >
                        <li className='hver_05'>
                          {/* <Link to='/outsource'> */}
                          Hire Developer/Designer
                          {/* </Link> */}
                        </li>
                      </Link>
                      {/* <li className='hver_06'>
                        <Link to='/seo'>SEO
                        </Link>
                      </li>
                      <li className='hver_07'>
                        <Link to='/quality-assurance'>Quality Assurance
                        </Link>
                      </li> */}
                      <Link
                        to='#'
                        className='w-100 p-0 text-dark'
                        style={{ cursor: 'initial' }}
                      >
                        <li className='hver_08'>
                          {/* <Link to='/game-development'> */}
                          Game Development
                          {/* </Link> */}
                        </li>
                      </Link>
                    </ul>
                  </li>
                  {/* <li>
                    <Link id='cudtn_mnu' to='/work'>
                      Work
                    </Link>
                  </li>
                  <li>
                    <Link id='cudtn_mnu' to='/event'>
                      Event
                    </Link>
                  </li>
                  <li>
                    <Link id='cudtn_mnu' to='/testimonial'>
                      Testimonials
                    </Link>
                  </li>
                  <li>
                    <Link id='cudtn_mnu' to='/blog'>
                      Blog
                    </Link>
                  </li> */}
                  <li>
                    <Link to='/contact-us' className='my-0 py-0'>
                      <button
                        className='gnrl_red_btn'
                        // onclick="window.location.href='contact_us.html'"
                      >
                        Contact Us
                        <img src={contactUs} alt='' />
                      </button>
                    </Link>
                  </li>
                </ul>
              </nav>
              <span
                className='mobile_menu_btn'
                style={{
                  fontSize: '30px',
                  cursor: 'pointer',
                  color: '#323232',
                }}
                onClick={(e) => openNavbar(e)}
              >
                ☰
              </span>
              <div className='mobile_menu'>
                <div
                  id='myNav'
                  className={classnames(
                    'overlay  animate__animated ',
                    {
                      animate__slideInLeft: displayClass === 'block',
                    },
                    {
                      animate__slideOutLeft: demo === true,
                    }
                  )}
                  style={{ display: displayClass }}
                >
                  <Link
                    to='#'
                    className='closebtn'
                    onClick={(e) => closeNavbar(e)}
                  >
                    ×
                  </Link>
                  <div className='overlay-content'>
                    <Link id='cudtn_mnu' to='/' onClick={(e) => closeNavbar(e)}>
                      Home
                    </Link>
                    <Link
                      id='cudtn_mnu'
                      to='/about-us'
                      onClick={(e) => closeNavbar(e)}
                    >
                      About Us
                    </Link>
                    <Link
                      id='cudtn_mnu'
                      className='menu_click'
                      // to="#"
                      onClick={(e) => toggleServicesMenu(e)}
                    >
                      Services <img src={drop} alt='' />
                    </Link>
                    <div
                      className='menu_drop_main animate__animated '
                      hidden={openSettings}
                    >
                      <Link
                        to='/uiux-design'
                        id='cudtn_mnu'
                        onClick={(e) => closeNavbar(e)}
                      >
                        UI UX Design
                      </Link>
                      <Link
                        id='cudtn_mnu'
                        to='/web-development'
                        onClick={(e) => closeNavbar(e)}
                      >
                        Web Development
                      </Link>
                      <Link
                        to='/app-development'
                        onClick={(e) => closeNavbar(e)}
                      >
                        App Development
                      </Link>
                      <Link
                        to='/graphics-design'
                        onClick={(e) => closeNavbar(e)}
                      >
                        Graphics design
                      </Link>
                      <Link to='/outsource' onClick={(e) => closeNavbar(e)}>
                        Outsource
                      </Link>
                      <Link to='/seo' onClick={(e) => closeNavbar(e)}>
                        SEO
                      </Link>
                      <Link
                        to='/quality-assurance'
                        onClick={(e) => closeNavbar(e)}
                      >
                        Quality Assurance
                      </Link>
                      <Link
                        to='/game-development'
                        onClick={(e) => closeNavbar(e)}
                      >
                        Game Development
                      </Link>
                    </div>
                    {/* <Link
                      id="cudtn_mnu"
                      to="/work"
                      onClick={(e) => closeNavbar(e)}
                    >
                      Work
                    </Link> */}
                    {/* <Link
                      id="cudtn_mnu"
                      to="/event"
                      onClick={(e) => closeNavbar(e)}
                    >
                      Event
                    </Link> */}
                    {/* <Link
                      id="cudtn_mnu"
                      to="/testimonial"
                      onClick={(e) => closeNavbar(e)}
                    >
                      Testimonials
                    </Link> */}
                    {/* <Link
                      id="cudtn_mnu"
                      to="/blog"
                      onClick={(e) => closeNavbar(e)}
                    >
                      Blog
                    </Link> */}
                    <Link to='/contact-us'>
                      <button className='gnrl_red_btn'>
                        Contact Us
                        <img src={contactUs} alt='' />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};
export default DefaultHeader;

import React, { Fragment } from "react";
import work from "../../assets/img/work_illustration.svg";
import img1 from "../../assets/img/portfolio_img/image_01.png";
import img2 from "../../assets/img/portfolio_img/image_02.png";
import img3 from "../../assets/img/portfolio_img/image_03.png";
import web1 from "../../assets/img/portfolio_img/web_01.png";
import web2 from "../../assets/img/portfolio_img/web_02.png";
import web3 from "../../assets/img/portfolio_img/web_03.png";
import web4 from "../../assets/img/portfolio_img/web_04.png";
import ecom1 from "../../assets/img/portfolio_img/ecommerce_01.png";
import ecom2 from "../../assets/img/portfolio_img/ecommerce_02.png";
import ecom3 from "../../assets/img/portfolio_img/ecommerce_03.png";
import uiux1 from "../../assets/img/portfolio_img/UIUX_01.png";
import uiux2 from "../../assets/img/portfolio_img/UIUX_02.png";
import uiux3 from "../../assets/img/portfolio_img/UIUX_03.png";
import brnd1 from "../../assets/img/portfolio_img/branding_01.png";
import brnd2 from "../../assets/img/portfolio_img/branding_02.png";
import brnd3 from "../../assets/img/portfolio_img/branding_03.png";
import grp1 from "../../assets/img/portfolio_img/graphics_01.png";
import grp2 from "../../assets/img/portfolio_img/graphics_02.png";
import grp3 from "../../assets/img/portfolio_img/graphics_03.png";
import grp4 from "../../assets/img/portfolio_img/graphics_04.png";
import blackArrow from "../../assets/img/button_black_arrow.svg";
import btnArrWhile from "../../assets/img/button_white_arrow.svg";

const Work = () => {
  return (
    <Fragment>
      {/* Section-1 */}
      <section className="event_01 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInLeft">
              <p className="section_title">
                Our <span>Work</span>
              </p>
              <p className="gnrl_para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInRight">
              <img src={work} alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* Section-2 */}
      <section
        className="home_sec_08 mt-5 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p className="section_title">
                Our <span>Work</span>
              </p>
              <p className="gnrl_para mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s.
              </p>
            </div>
            <div className="col-lg-12 mt-4">
              <div align="center">
                {/* <button class="filter-button" data-filter="all">All</button> */}
                <button
                  className="filter-button defaultSelected"
                  data-filter="mobile_app"
                >
                  Mobile App
                </button>
                <button className="filter-button" data-filter="web_design">
                  Web Design
                </button>
                <button className="filter-button" data-filter="ecommerce">
                  Ecommerce
                </button>
                <button className="filter-button" data-filter="UIUX">
                  UI/UX
                </button>
                <button className="filter-button" data-filter="branding">
                  Branding
                </button>
                <button className="filter-button" data-filter="graphics">
                  Graphics Design
                </button>
              </div>
            </div>
            <div className="col-lg-12 mt-5">
              <div className="row">
                {/*-------- Mobile app section --------*/}
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter mobile_app">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Discover
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button
                          className="gnrl_black_brder_btn mt-5"
                          onclick="window.location.href='project_details.html'"
                        >
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={img1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter mobile_app">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Discover
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={img2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter mobile_app">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Discover
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={img3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter mobile_app">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Discover
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={img1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                {/*-------- web design section --------*/}
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter web_design">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Landrover
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={web1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter web_design">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Stident HOB
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={web2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter web_design">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Writing web
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={web3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter web_design">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          TGVIST
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={web4} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                {/*-------- Ecommerce section --------*/}
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter ecommerce">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Landrover
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={ecom1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter ecommerce">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Stident HOB
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={ecom2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter ecommerce">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Writing web
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={ecom3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter ecommerce">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          TGVIST
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={ecom1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                {/*-------- UI/UX section --------*/}
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter UIUX">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Landrover
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={uiux1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter UIUX">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Stident HOB
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={uiux2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter UIUX">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Writing web
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={uiux3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter UIUX">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          TGVIST
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={uiux1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                {/*-------- Branding section --------*/}
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter branding">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Landrover
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={brnd1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter branding">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Stident HOB
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={brnd2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter branding">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Writing web
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={brnd3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter branding">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          TGVIST
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={brnd1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                {/*-------- Graphics design section --------*/}
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter graphics">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Landrover
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={grp1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter graphics">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Stident HOB
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={grp2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter graphics">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          Writing web
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={grp3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter graphics">
                  <div className="portfolio_card_main">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4">
                        <p className="mediam_txt mt-2 w-100 float-left">
                          TGVIST
                        </p>
                        <span className="mediam_txt_btm_line" />
                        <p className="gnrl_para mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className="gnrl_black_brder_btn mt-5">
                          View more
                          <img src={blackArrow} alt="" />
                        </button>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center">
                        <img src={grp4} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section-3 */}
      <div>
        <section
          className="get_free_quot_sec mt-5 pb-2 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                <p className="section_title">
                  Ask for a <span>Proposal</span> – fill out simple form and we
                  will get back to you
                </p>
              </div>
              <div className="col-lg-3 col-md-12 text-right">
                <button
                  className="gnrl_red_btn"
                  data-toggle="modal"
                  data-target="#get_free_qout"
                >
                  Get free quote
                  <img src={btnArrWhile} alt="" />
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* The Modal */}
        <div className="modal" id="get_free_qout">
          <div className="modal-dialog">
            <div className="modal-content p-2">
              {/* Modal Header */}
              <div className="modal-header w-100 float-left border-0 pb-0">
                <p className="section_title">
                  Inquiry <span>Form</span>
                </p>
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              {/* Modal body */}
              <div className="modal-body w-100 float-left">
                <div className="apply_for_job">
                  <input type="text" placeholder="Your Name" />
                  <input type="email" placeholder="Your Email" />
                  <textarea placeholder="Your Message" defaultValue={""} />
                  <button className="gnrl_red_btn mt-4">Submit</button>
                </div>
              </div>
              {/* Modal footer */}
              {/* <div class="modal-footer w-100 float-left">
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Work;

import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import homeIllu from '../../assets/img/home_illustration.svg';
import leaf from '../../assets/img/home_red_leaf.svg';
import redDot from '../../assets/img/red_dot.svg';
import yoga from '../../assets/img/pro_img/yoga_app.png';
import l1 from '../../assets/img/client_logo/logo_01.png';
import l2 from '../../assets/img/client_logo/logo_02.png';
import l3 from '../../assets/img/client_logo/logo_03.png';
import l4 from '../../assets/img/client_logo/logo_04.png';
import l5 from '../../assets/img/client_logo/logo_05.png';
import l6 from '../../assets/img/client_logo/logo_06.png';
import l7 from '../../assets/img/client_logo/logo_07.png';
import discover from '../../assets/img/discover_icon.svg';
import design from '../../assets/img/design_icon.svg';
import build from '../../assets/img/build_icon.svg';
import qa from '../../assets/img/QA_icon.svg';
import deliver from '../../assets/img/deliver_icon.svg';
import support from '../../assets/img/support_icon.svg';
import uiux from '../../assets/img/Services_illu/UIUX.svg';
import web from '../../assets/img/Services_illu/web_development.svg';
import app from '../../assets/img/Services_illu/app_development.svg';
import graphics from '../../assets/img/Services_illu/graphics_design.svg';
import outSource from '../../assets/img/Services_illu/outsource.svg';
import seo from '../../assets/img/Services_illu/SEO.svg';
import QA from '../../assets/img/Services_illu/QA.svg';
import game from '../../assets/img/Services_illu/game_development.svg';
import technologyWork from '../../assets/img/technology_we_work_with.svg';
import techIcon from '../../assets/img/technology_icon.svg';
import ecom1 from '../../assets/img/portfolio_img/ecommerce_01.png';
import ecom2 from '../../assets/img/portfolio_img/ecommerce_02.png';
import ecom3 from '../../assets/img/portfolio_img/ecommerce_03.png';
import brnd1 from '../../assets/img/portfolio_img/branding_01.png';
import brnd2 from '../../assets/img/portfolio_img/branding_02.png';
import brnd3 from '../../assets/img/portfolio_img/branding_03.png';
import blog1 from '../../assets/img/blog_img/img_01.jpg';
import blog2 from '../../assets/img/blog_img/img_02.jpg';
import blogArrow from '../../assets/img/blog_btn_arrow.svg';
import WOW from 'wow.js';

import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from 'reactstrap';

import {
  blackArrow,
  whatWeAre,
  pw1,
  pw2,
  pw3,
  pw4,
  flutter,
  android,
  apple,
  whiteArrow,
  graphics1,
  graphics2,
  graphics3,
  graphics4,
  square,
  secCurve,
  pa2,
  pa1,
  pa3,
  pui1,
  pui2,
  pui3,
} from '../../AllImages/AllImages';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HomePage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const wow = new WOW();
    wow.init();
  }, []);

  // ============  Projects Slider Start ============
  const items = [
    {
      src: yoga,
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum.',
    },
    {
      src: yoga,
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum.',
    },
    {
      src: yoga,
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum.',
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className='carousel-item active boat '>
          <div className='container d-md-block'>
            <div className='row align-items-center h-100'>
              <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                <div className='caption animated fadeIn'>
                  <div
                    className='row'
                    style={{
                      paddingLeft: '40px',
                      paddingRight: '40px',
                      paddingTop: '40px',
                      paddingBottom: '32px',
                    }}
                  >
                    <div className='col-lg-6 col-md-6'>
                      <div className='home_sec_03_mob_img'>
                        <img src={item.src} alt='' />
                        <img className='doted_squere' src={square} alt='' />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='home_sec_03_right'>
                        <p className='mediam_txt'>Passive Lane</p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem ipsum doler sit amet this is a dummy text for
                          latest projct discription about this project. lorm
                          ipsum doler sit ame this is a dummy text here.
                        </p>
                        <p className='mediam_txt mt-3'>Technology we used</p>
                        <span className='tech_icon mt-2'>
                          <img src={flutter} alt='' />
                        </span>
                        <div className='w-100 float-left'>
                          <p className='mediam_txt mt-3'>Platform</p>
                          <span className='tech_icon mt-2'>
                            <img src={android} alt='' />
                          </span>
                          <span className='tech_icon ml-3 mt-2'>
                            <img src={apple} alt='' />
                          </span>
                        </div>
                        <div className='w-100 float-left'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CarouselItem>
    );
  });
  // ============ Projects Slider Stop ============

  var settings = {
    // dots: true,
    className: 'customer-logos slider mt-5 ml-3  w-100',
    infinite: true,
    speed: 500,
    slidesStartFrom: 1,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          initialSlide: 1,

          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 1,
        },
      },
    ],
  };

  // ============ customer Slider Start ============

  const customers = [
    {
      src: 'https://image.ibb.co/iN3qES/pexels_photo_324658.jpg',
      title: 'Lorem P. Ipsum',
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum.',
    },
    {
      src: 'https://image.ibb.co/cNP817/pexels_photo_220453.jpg',
      title: 'Lorem P. Ipsum',
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum.',
    },
    {
      src: 'https://image.ibb.co/iN3qES/pexels_photo_324658.jpg',
      title: 'Lorem P. Ipsum',
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum.',
    },
    {
      src: 'https://image.ibb.co/kL6AES/Top_SA_Nicky_Oppenheimer.jpg',
      title: 'Lorem P. Ipsum',
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum.',
    },
    {
      src: 'https://image.ibb.co/gUPag7/image.jpg',
      title: 'Lorem P. Ipsum',
      description:
        'Lorem Ipsum is simply dummy text of the printing and web development typesetting this is app development dunny text here sinc 1863industry. Lorem Ipsum.',
    },
  ];

  const [currentIndex, setcurrentIndex] = useState(0);
  const [animation, setanimation] = useState(false);

  const nextSlide = () => {
    if (animation) return;
    const nextIndex =
      currentIndex === customers.length - 1 ? 0 : currentIndex + 1;
    setcurrentIndex(nextIndex);
  };

  const previousSlide = () => {
    if (animation) return;
    const nextIndex =
      currentIndex === 0 ? customers.length - 1 : currentIndex - 1;
    setcurrentIndex(nextIndex);
  };

  const goToSlide = (newIndex) => {
    if (animation) return;
    setcurrentIndex(newIndex);
  };
  const customerSlides = customers.map((customer) => {
    return (
      <CarouselItem
        onExiting={() => setanimation(true)}
        onExited={() => setanimation(false)}
        key={customer.src}
      >
        <div className='container px-5'>
          <div className='img'>
            <img className='doted_squere' src={square} alt='' />
            <img src={customer.src} alt='' />
          </div>
          <h2 className='mediam_txt mt-4'>Mr. Lorem Ipsum</h2>
          <p className='gnrl_para mt-2 '>{customer.description}</p>
        </div>
        {/* <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />*/}
      </CarouselItem>
    );
  });
  //  ============ customer Slider end ============

  return (
    <Fragment>
      {/* section -1  */}
      <section className='home_sec_01 mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='home_sec_01_left wow animate__animated animate__fadeInLeft'>
                <p className='mediam_txt'>
                  Top Web and Mobile Apps Development Company
                </p>
                <p className='page_title mt-2'>
                  Your Trusted
                  <br />
                  Development
                  <br />
                  Partner
                </p>
                <p className='gnrl_para mt-2 '>
                  We achieve results, and results are what really matter. In a
                  good partnership, good results happen. Good cooking makes good
                  eating.
                </p>
                <Link to='/contact-us'>
                  <button className='gnrl_black_brder_btn mt-2'>
                    Get free quote
                    <img src={blackArrow} alt='' />
                  </button>
                </Link>
                <img className='doted_squere' src={square} alt='' />
                <img className='red_leaf' src={leaf} alt='' />
              </div>
            </div>
            <div className='col-lg-6 col-md-6 home_sec_01_right'>
              <div className='home_sec_01_right wow animate__animated animate__fadeInRight'>
                <img src={homeIllu} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section -2  */}
      <section
        className='home_sec_02 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>What we are?</p>
              <p className='gnrl_para mt-2'>
                We, Saturncube Technologies established in 2014 have served more
                than 1500 clients for Web Applications, <br /> Mobile
                Applications and Graphic Design solutions with help of our
                skilled team and years of experience.
              </p>
            </div>
            <div className='col-lg-10 col-md-12 mt-5 mb-5'>
              <div className='row'>
                <div className='col-lg-6 col-md-6'>
                  <div className='home_sec_02_left'>
                    <img src={whatWeAre} alt='' />
                  </div>
                </div>
                <div className='col-lg-6 col-md-6'>
                  <div className='home_sec_02_right'>
                    <ul>
                      <li>
                        <img src={redDot} alt='' />
                        <p>UI UX Design</p>
                      </li>
                      <li>
                        <img src={redDot} alt='' />
                        <p>Web Development</p>
                      </li>
                      <li>
                        <img src={redDot} alt='' />
                        <p>App Development</p>
                      </li>
                      <li>
                        <img src={redDot} alt='' />
                        <p>Graphics design</p>
                      </li>
                      <li>
                        <img src={redDot} alt='' />
                        <p>Outsource</p>
                      </li>
                      <li>
                        <img src={redDot} alt='' />
                        <p>Game Development</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className='sec_curve_img' src={secCurve} alt='' />
      </section>
      {/* section - 3 */}

      {/* <section
        className="home_sec_03 mt-5 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p className="section_title">
                Our Latest <span>Projects</span>
              </p>
              <p className="gnrl_para mt-2">
               Wondering how we have done so far? Take a look at our portfolio and see what we have delivered so far.

              </p>
            </div>
            <div className="col-lg-12 ">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={slides}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </Carousel>
            </div>
          </div>
        </div>
      </section> */}
      {/* section - 4 */}
      {/* <section
        // className='home_sec_04 mt-5 wow animate__animated animate__fadeInUp'
        className='home_sec_04 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                Our <span>clients</span>
              </p>
              <p className='gnrl_para mt-2'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s.
              </p>
            </div>
            <div className='col-lg-12 mt-4'>
              <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-6  text-center mt-3'>
                  <img src={l1} alt='' />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-6 col-6  text-center mt-3'>
                  <img src={l2} alt='' />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-6 col-6  text-center mt-3'>
                  <img src={l3} alt='' />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-6 col-6  text-center mt-3'>
                  <img src={l4} alt='' />
                </div>
              </div>
              <div className='col-lg-10 col-md-10'>
                <div className='row'>
                  <div className='col-lg-4 col-md-3 col-sm-6 col-6 text-center mt-3'>
                    <img src={l5} alt='' />
                  </div>
                  <div className='col-lg-4 col-md-3 col-sm-6 col-6 text-center mt-3'>
                    <img src={l6} alt='' />
                  </div>
                  <div className='col-lg-4 col-md-3 col-sm-6 col-6 text-center mt-3'>
                    <img src={l7} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* section - 5 */}
      <section
        className='home_sec_05 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                Software <span>Development</span> Process
              </p>
              <p className='gnrl_para mt-2'>
                We have proven framework that defines the steps involved in the
                development of software at each phase.
                <br />
                It covers the detailed plan for building, deploying and
                maintaining the software.
              </p>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5'>
              <span className='develpo_pro_icon1'>
                <img src={discover} alt='' />
              </span>
              <p className='mediam_txt mt-2'>Discover</p>
              <p className='gnrl_para'>
                Let's sit together, do brainstorm and put thoughts on paper to
                make executable plan. We will help you out to convert your idea
                in reality.
              </p>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5'>
              <span className='develpo_pro_icon2'>
                <img src={design} alt='' />
              </span>
              <p className='mediam_txt mt-2'>Design</p>
              <p className='gnrl_para'>
                Give your Business Idea a unique face with our User Experience
                and Design Experts.
              </p>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5'>
              <span className='develpo_pro_icon3'>
                <img src={build} alt='' />
              </span>
              <p className='mediam_txt mt-2'>Build</p>
              <p className='gnrl_para'>
                Our developers will start converting Design to Real App meeting
                your requirements with periodically progress update.
              </p>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5'>
              <span className='develpo_pro_icon4'>
                <img src={qa} alt='' />
              </span>
              <p className='mediam_txt mt-2'>QA</p>
              <p className='gnrl_para'>
                Product defects are reported, tracked, fixed and retested, until
                the product reaches the quality standards by our Quality
                Assurance Team.
              </p>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5'>
              <span className='develpo_pro_icon5'>
                <img src={deliver} alt='' />
              </span>
              <p className='mediam_txt mt-2'>Deliver</p>
              <p className='gnrl_para'>
                Time to see your idea in action now! We will deploy app on
                server, share with you to review and give us green signal to
                make it public.
              </p>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5'>
              <span className='develpo_pro_icon6'>
                <img src={support} alt='' />
              </span>
              <p className='mediam_txt mt-2'>Support</p>
              <p className='gnrl_para'>
                Once app starts getting used in real life, then the real issues
                come up and requirements to be solved from time to time. We will
                be there to handle if anything comes.
              </p>
            </div>
          </div>
        </div>
        <img className='doted_squere' src={square} alt='' />
      </section>
      {/* section - 6 */}
      {/* <section
        className='home_sec_06 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                Our Core <span>Services</span>
              </p>
              <p className='gnrl_para mt-2'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s.
              </p>
            </div>
            <Slider {...settings}>
              <div className='slide service_card_01'>
                <span className='service_card_img'>
                  <img src={uiux} alt='' />
                </span>
                <p
                  className='mediam_txt cursor mt-3'
                  onClick={() => {
                    props.history.push('/uiux-design');
                  }}
                >
                  UI/UX Design
                </p>
                <p className='gnrl_para'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum.
                </p>
              </div>
              <div className='slide service_card_02'>
                <span className='service_card_img'>
                  <img src={web} alt='' />
                </span>
                <p
                  className='mediam_txt cursor mt-3'
                  onClick={() => {
                    props.history.push('/web-development');
                  }}
                >
                  Web Development
                </p>
                <p className='gnrl_para'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum.
                </p>
              </div>
              <div className='slide service_card_03'>
                <span className='service_card_img'>
                  <img src={app} alt='' />
                </span>
                <p
                  className='mediam_txt cursor mt-3'
                  onClick={() => {
                    props.history.push('/app-development');
                  }}
                >
                  App Development
                </p>
                <p className='gnrl_para'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum.
                </p>
              </div>
              <div className='slide service_card_04'>
                <span className='service_card_img'>
                  <img src={graphics} alt='' />
                </span>
                <p
                  className='mediam_txt cursor mt-3'
                  onClick={() => {
                    props.history.push('/graphics-design');
                  }}
                >
                  Graphics Design
                </p>
                <p className='gnrl_para'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum.
                </p>
              </div>
              <div className='slide service_card_05'>
                <span className='service_card_img'>
                  <img src={outSource} alt='' />
                </span>
                <p
                  className='mediam_txt cursor mt-3'
                  onClick={() => {
                    props.history.push('/outsource');
                  }}
                >
                  Outsource
                </p>
                <p className='gnrl_para'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum.
                </p>
              </div>
              <div className='slide service_card_06'>
                <span className='service_card_img'>
                  <img src={seo} alt='' />
                </span>
                <p
                  className='mediam_txt cursor mt-3'
                  onClick={() => {
                    props.history.push('/seo');
                  }}
                >
                  SEO
                </p>
                <p className='gnrl_para'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum.
                </p>
              </div>
              <div className='slide service_card_07'>
                <span className='service_card_img'>
                  <img src={QA} alt='' />
                </span>
                <p
                  className='mediam_txt cursor mt-3'
                  onClick={() => {
                    props.history.push('/quality-assurance');
                  }}
                >
                  Quality Assurance
                </p>
                <p className='gnrl_para'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum.
                </p>
              </div>
              <div className='slide service_card_08'>
                <span className='service_card_img'>
                  <img src={game} alt='' />
                </span>
                <p
                  className='mediam_txt cursor mt-3'
                  onClick={() => {
                    props.history.push('/game-development');
                  }}
                >
                  Game Development
                </p>
                <p className='gnrl_para'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum.
                </p>
              </div>
            </Slider>
          </div>
        </div>
      </section> */}
      {/* section - 7 */}
      <section
        className='home_sec_07 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                <span>Technologies</span> we work with
              </p>
              <p className='gnrl_para mt-2'>
                We serve our services on following area of Start-up, Small
                Business, Enterprise Business.
                <br /> We always welcome you to invite us as tech partner of
                your company.
              </p>
            </div>
            <div className='col-lg-5 col-md-5 col-sm-5 home_sec_07_left mt-5'>
              <div>
                <img src={technologyWork} alt='' />
              </div>
            </div>
            <div className='col-lg-7 col-md-7 col-sm-7 home_sec_07_right mt-5'>
              <div>
                <img src={techIcon} alt='' />
              </div>
            </div>
          </div>
        </div>
        <img className='doted_squere' src={square} alt='' />
      </section>
      {/* section - 8 */}
      {/* <section
        className='get_free_quot_sec mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-9 col-md-12'>
              <p className='section_title'>
                Ask for a <span>Proposal</span> – fill out simple form and we
                will get back to you
              </p>
            </div>
            <div className='col-lg-3 col-md-12 text-right'>
              <button
                className='gnrl_red_btn'
                data-toggle='modal'
                data-target='#get_free_qout'
              >
                Get free quote
                <img src={whiteArrow} alt='' />
              </button>
            </div>
          </div>
        </div>
      </section> */}

      {/* ------- Modal Start ------- */}
      <div className='modal' id='get_free_qout'>
        <div className='modal-dialog'>
          <div className='modal-content p-2'>
            {/* Modal Header */}
            <div className='modal-header w-100 float-left border-0 pb-0'>
              <p className='section_title'>
                Inquiry <span>Form</span>
              </p>
              <button type='button' className='close' data-dismiss='modal'>
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className='modal-body w-100 float-left'>
              <div className='apply_for_job'>
                <input type='text' placeholder='Your Name' />
                <input type='email' placeholder='Your Email' />
                <textarea placeholder='Your Message' defaultValue={''} />
                <button className='gnrl_red_btn mt-4'>Submit</button>
              </div>
            </div>
            {/* Modal footer */}
            {/* 
            <div className="modal-footer w-100 float-left">
    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
  </div>
   */}
          </div>
        </div>
      </div>
      {/* ------- Modal End ------- */}

      {/* section - 9 */}
      {/* <section
        className='home_sec_08 mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                Our <span>Work</span>
              </p>
              <p className='gnrl_para mt-2'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s.
              </p>
            </div>
            <div className='col-lg-12 mt-4'>
              <div align='center'>
                <button
                  className='filter-button defaultSelected'
                  data-filter='mobile_app'
                >
                  Mobile App
                </button>
                <button className='filter-button' data-filter='web_design'>
                  Web Design
                </button>
                <button className='filter-button' data-filter='ecommerce'>
                  Ecommerce
                </button>
                <button className='filter-button' data-filter='UIUX'>
                  UI/UX
                </button>
                <button className='filter-button' data-filter='branding'>
                  Branding
                </button>
                <button className='filter-button' data-filter='graphics'>
                  Graphics Design
                </button>
              </div>
            </div>
            <div className='col-lg-12 mt-5'>
              <div className='row'>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter mobile_app'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pa1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter mobile_app'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pa2} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter mobile_app'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pa3} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter mobile_app'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Discover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pa1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter web_design'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Landrover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pw1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter web_design'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Stident HOB
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pw2} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter web_design'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Writing web
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pw3} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter web_design'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          TGVIST
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pw4} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter ecommerce'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Landrover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={ecom1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter ecommerce'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Stident HOB
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={ecom2} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter ecommerce'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Writing web
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={ecom3} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter ecommerce'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          TGVIST
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={ecom1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter UIUX'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Landrover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pui1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter UIUX'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Stident HOB
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pui2} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter UIUX'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Writing web
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pui3} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter UIUX'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          TGVIST
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={pui1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter branding'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Landrover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={brnd1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter branding'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Stident HOB
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={brnd2} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter branding'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Writing web
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={brnd3} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter branding'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          TGVIST
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <Link to='/project-details'>
                          <button className='gnrl_black_brder_btn mt-5'>
                            View more
                            <img src={blackArrow} alt='' />
                          </button>
                        </Link>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={brnd1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter graphics'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Landrover
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={graphics1} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter graphics'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Stident HOB
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={graphics2} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter graphics'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          Writing web
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={graphics3} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='gallery_product col-lg-6 col-md-12 col-sm-12 col-12 filter graphics'>
                  <div className='portfolio_card_main'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-5 col-sm-5 col-12 custm_lef-pad pt-3 pb-4'>
                        <p className='mediam_txt mt-2 w-100 float-left'>
                          TGVIST
                        </p>
                        <span className='mediam_txt_btm_line' />
                        <p className='gnrl_para mt-3'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                        <button className='gnrl_black_brder_btn mt-5'>
                          View more
                          <img src={blackArrow} alt='' />
                        </button>
                      </div>
                      <div className='col-lg-7 col-md-7 col-sm-7 col-12 portfolio_card_right text-center'>
                        <img src={graphics4} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-12 text-center'>
              <button className='gnrl_red_btn'>View all</button>
            </div>
          </div>
        </div>
      </section> */}
      {/* section - 10 */}
      {/* <section
        // className='home_sec_09 mt-5 wow animate__animated animate__fadeInUp'
        className='home_sec_09 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                What our <span>customers</span> say
              </p>
              <p className='gnrl_para mt-2'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s.
              </p>
            </div>
            <div className='col-lg-12'>
            
              <Carousel
                activeIndex={currentIndex}
                next={nextSlide}
                previous={previousSlide}
                autoPlay={false}
              >
                <CarouselIndicators
                  items={customerSlides}
                  activeIndex={currentIndex}
                  onClickHandler={goToSlide}
                />
                {customerSlides}
                <CarouselControl
                  className='ml-0 pl-0'
                  direction='prev'
                  directionText='Previous'
                  onClickHandler={previousSlide}
                />
                <CarouselControl
                  style={{ right: '-91px' }}
                  direction='next'
                  directionText='Next'
                  onClickHandler={nextSlide}
                />
              </Carousel>
           
            </div>
          </div>
        </div>
      </section> */}

      {/* section - 11 */}

      {/* <section
        className='blog_main mt-5 wow animate__animated animate__fadeInUp'
        data-wow-duration='1s'
        data-wow-delay='0.2s'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <p className='section_title'>
                Our <span>blog</span>
              </p>
              <p className='gnrl_para mt-2'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s.
              </p>
            </div>
            <div className='col-lg-11'>
              <div className='row'>
                <div className='col-lg-4 col-md-4 mt-4'>
                  <div className='blog_main_box'>
                    <div className='blog_main_box_img'>
                      <img src={blog1} alt='' />
                    </div>
                    <div className='blog_main_box_txt'>
                      <p className='mediam_txt'>UI/UX Design</p>
                      <p className='extra_small_txt'>July 8, 2020</p>
                      <p className='gnrl_para mt-2 mb-0'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className='float-left w-100'>
                        <Link to='/blog'>
                          <button className='blog_btn'>
                            View <img src={blogArrow} alt='' />
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4 mt-4'>
                  <div className='blog_main_box'>
                    <div className='blog_main_box_img'>
                      <img src={blog2} alt='' />
                    </div>
                    <div className='blog_main_box_txt'>
                      <p className='mediam_txt'>UI/UX Design</p>
                      <p className='extra_small_txt'>July 8, 2020</p>
                      <p className='gnrl_para mt-2 mb-0'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className='float-left w-100'>
                        <Link to='/blog'>
                          <button className='blog_btn'>
                            View <img src={blogArrow} alt='' />
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4 mt-4'>
                  <div className='blog_main_box'>
                    <div className='blog_main_box_img'>
                      <img src={blog1} alt='' />
                    </div>
                    <div className='blog_main_box_txt'>
                      <p className='mediam_txt'>UI/UX Design</p>
                      <p className='extra_small_txt'>UI/UX July 8, 2020&gt;</p>
                      <p className='gnrl_para mt-2 mb-0'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className='float-left w-100'>
                        <Link to='/blog'>
                          <button className='blog_btn'>
                            View <img src={blogArrow} alt='' />
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className='doted_squere' src={square} alt='' />
        <img className='doted_squere2' src={square} alt='' />
      </section> */}
    </Fragment>
  );
};
export default HomePage;

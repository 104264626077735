import React, { useEffect } from "react";
import { Fragment } from "react";
import {
  testimonialIllu,
  testimoinial3,
  testimoinial2,
  testimoinial1,
  square,
  secCurve,
} from "../../AllImages/AllImages";

const Testimonial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div className="testimonial_01 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInLeft">
              <p className="section_title">
                Words from <span>clients</span>
              </p>
              <p className="gnrl_para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInRight">
              <img src={testimonialIllu} alt="" />
            </div>
          </div>
        </div>
      </div>
      <section className="testimonial_02">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 text-center wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <p className="section_title">
                <span>Video</span> Testimonial
              </p>
              <p className="gnrl_para mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a<br />
                type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting,
              </p>
            </div>
            <div className="col-lg-10">
              <div className="row">
                <div
                  className="col-lg-6 col-md-6 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="testimonial_02_video drop_shedow_01">
                    <iframe
                      title="1"
                      width="100%"
                      height={252}
                      src="https://www.youtube.com/embed/Fz7ABEqlUe0?controls=0"
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <p className="testimonial_02_video_title mt-3">
                    Tracey Hayim
                  </p>
                  <p className="gnrl_para">
                    Creative Director, Live Love Learn | Metamorphosis Design
                  </p>
                </div>
                <div
                  className="col-lg-6 col-md-6 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="testimonial_02_video drop_shedow_02">
                    <iframe
                      title="2"
                      width="100%"
                      height={252}
                      src="https://www.youtube.com/embed/Fz7ABEqlUe0?controls=0"
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <p className="testimonial_02_video_title mt-3">
                    Tracey Hayim
                  </p>
                  <p className="gnrl_para">
                    Creative Director, Live Love Learn | Metamorphosis Design
                  </p>
                </div>
                <div
                  className="col-lg-6 col-md-6 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="testimonial_02_video drop_shedow_03">
                    <iframe
                      title="3"
                      width="100%"
                      height={252}
                      src="https://www.youtube.com/embed/Fz7ABEqlUe0?controls=0"
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <p className="testimonial_02_video_title mt-3">
                    Tracey Hayim
                  </p>
                  <p className="gnrl_para">
                    Creative Director, Live Love Learn | Metamorphosis Design
                  </p>
                </div>
                <div
                  className="col-lg-6 col-md-6 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="testimonial_02_video drop_shedow_04">
                    <iframe
                      title="4"
                      width="100%"
                      height={252}
                      src="https://www.youtube.com/embed/Fz7ABEqlUe0?controls=0"
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <p className="testimonial_02_video_title mt-3">
                    Tracey Hayim
                  </p>
                  <p className="gnrl_para">
                    Creative Director, Live Love Learn | Metamorphosis Design
                  </p>
                </div>
                <div
                  className="col-lg-6 col-md-6 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="testimonial_02_video drop_shedow_05">
                    <iframe
                      title="5"
                      width="100%"
                      height={252}
                      src="https://www.youtube.com/embed/Fz7ABEqlUe0?controls=0"
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <p className="testimonial_02_video_title mt-3">
                    Tracey Hayim
                  </p>
                  <p className="gnrl_para">
                    Creative Director, Live Love Learn | Metamorphosis Design
                  </p>
                </div>
                <div
                  className="col-lg-6 col-md-6 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="testimonial_02_video drop_shedow_06">
                    <iframe
                      title="6"
                      width="100%"
                      height={252}
                      src="https://www.youtube.com/embed/Fz7ABEqlUe0?controls=0"
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <p className="testimonial_02_video_title mt-3">
                    Tracey Hayim
                  </p>
                  <p className="gnrl_para">
                    Creative Director, Live Love Learn | Metamorphosis Design
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="sec_curve_img" src={secCurve} alt="" />
      </section>
      <section className="testimonial_03 mt-5">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 text-center testimonial_03_main mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <span className="testimonial_03_profile">
                <img src={testimoinial1} alt="" />
              </span>
              <p className="testimonial_03_title">Tracey Hayim</p>
              <p className="gnrl_para">
                Creative Director, Live Love Learn | Metamorphosis Design
              </p>
              <p className="gnrl_para">
                Live Love Learn and Metamorphosis Design have worked on numerous
                projects with Saturn Cube Technology and their team and I can
                highly recommend their excellent service. They are so patient
                and understanding. Their eye for detail is just what you need to
                create an impressive website. They can follow a creative brief
                really well. Their understanding of a responsive up to date
                website is brilliant.
              </p>
              <img className="doted_squere" src={square} alt="" />
            </div>
            <div
              className="col-lg-6 col-md-6 text-center testimonial_03_main mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <span className="testimonial_03_profile">
                <img src={testimoinial2} alt="" />
              </span>
              <p className="testimonial_03_title">Tracey Hayim</p>
              <p className="gnrl_para">
                Creative Director, Live Love Learn | Metamorphosis Design
              </p>
              <p className="gnrl_para">
                Live Love Learn and Metamorphosis Design have worked on numerous
                projects with Saturn Cube Technology and their team and I can
                highly recommend their excellent service. They are so patient
                and understanding. Their eye for detail is just what you need to
                create an impressive website. They can follow a creative brief
                really well. Their understanding of a responsive up to date
                website is brilliant.
              </p>
              <img className="doted_squere" src={square} alt="" />
            </div>
            <div
              className="col-lg-6 col-md-6 text-center testimonial_03_main mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <span className="testimonial_03_profile">
                <img src={testimoinial3} alt="" />
              </span>
              <p className="testimonial_03_title">Tracey Hayim</p>
              <p className="gnrl_para">
                Creative Director, Live Love Learn | Metamorphosis Design
              </p>
              <p className="gnrl_para">
                Live Love Learn and Metamorphosis Design have worked on numerous
                projects with Saturn Cube Technology and their team and I can
                highly recommend their excellent service. They are so patient
                and understanding. Their eye for detail is just what you need to
                create an impressive website. They can follow a creative brief
                really well. Their understanding of a responsive up to date
                website is brilliant.
              </p>
              <img className="doted_squere" src={square} alt="" />
            </div>
            <div
              className="col-lg-6 col-md-6 text-center testimonial_03_main mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <span className="testimonial_03_profile">
                <img src={testimoinial2} alt="" />
              </span>
              <p className="testimonial_03_title">Tracey Hayim</p>
              <p className="gnrl_para">
                Creative Director, Live Love Learn | Metamorphosis Design
              </p>
              <p className="gnrl_para">
                Live Love Learn and Metamorphosis Design have worked on numerous
                projects with Saturn Cube Technology and their team and I can
                highly recommend their excellent service. They are so patient
                and understanding. Their eye for detail is just what you need to
                create an impressive website. They can follow a creative brief
                really well. Their understanding of a responsive up to date
                website is brilliant.
              </p>
              <img className="doted_squere" src={square} alt="" />
            </div>
            <div
              className="col-lg-6 col-md-6 text-center testimonial_03_main mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <span className="testimonial_03_profile">
                <img src={testimoinial3} alt="" />
              </span>
              <p className="testimonial_03_title">Tracey Hayim</p>
              <p className="gnrl_para">
                Creative Director, Live Love Learn | Metamorphosis Design
              </p>
              <p className="gnrl_para">
                Live Love Learn and Metamorphosis Design have worked on numerous
                projects with Saturn Cube Technology and their team and I can
                highly recommend their excellent service. They are so patient
                and understanding. Their eye for detail is just what you need to
                create an impressive website. They can follow a creative brief
                really well. Their understanding of a responsive up to date
                website is brilliant.
              </p>
              <img className="doted_squere" src={square} alt="" />
            </div>
            <div
              className="col-lg-6 col-md-6 text-center testimonial_03_main mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <span className="testimonial_03_profile">
                <img src={testimoinial2} alt="" />
              </span>
              <p className="testimonial_03_title">Tracey Hayim</p>
              <p className="gnrl_para">
                Creative Director, Live Love Learn | Metamorphosis Design
              </p>
              <p className="gnrl_para">
                Live Love Learn and Metamorphosis Design have worked on numerous
                projects with Saturn Cube Technology and their team and I can
                highly recommend their excellent service. They are so patient
                and understanding. Their eye for detail is just what you need to
                create an impressive website. They can follow a creative brief
                really well. Their understanding of a responsive up to date
                website is brilliant.
              </p>
              <img className="doted_squere" src={square} alt="" />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default Testimonial;

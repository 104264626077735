import React from "react";

import Lottie from "react-lottie";
import contact from "./lf30_UWktQI.json";

const contactUs = {
  loop: true,
  autoplay: true,
  background: "transparent",
  animationData: contact,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function ContactUsLottie() {
  return (
    <Lottie
      options={contactUs}
      height={420}
      width={420}
      isStopped={false}
      isPaused={false}
    />
  );
}

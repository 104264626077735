import React, { Fragment, useEffect } from "react";
import {
  blogBtnArrow,
  whiteArrow,
  blog1,
  blog2,
  blog3,
} from "../../AllImages/AllImages";

const BlogDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <section className="blog_details_01 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="blog_details_01_img">
                <img src={blog1} alt="" />
              </div>
            </div>
            <div
              className="col-lg-10 col-md-10 text-left mt-3 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <p className="section_title">
                Lorem ipsum doler sit amet
                <br />
                this is a dummy.
              </p>
            </div>
            <div
              className="col-lg-2 col-md-2 text-right mt-3 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <p className="gnrl_para">July 8, 2020</p>
            </div>
            <div
              className="col-lg-12 col-md-12 text-left mt-4 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <p className="gnrl_para">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32.
              </p>
              <p className="gnrl_para">
                The standard chunk of Lorem Ipsum used since the 1500s is
                reproduced below for those interested. Sections 1.10.32 and
                1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also
                reproduced in their exact original form, accompanied by English
                versions from the 1914 translation by H. Rackham.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="blog_main mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-11">
              <div className="row">
                <div
                  className="col-lg-4 col-md-4 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog_main_box">
                    <div className="blog_main_box_img">
                      <img src={blog1} alt="" />
                    </div>
                    <div className="blog_main_box_txt">
                      <p className="mediam_txt">UI/UX Design</p>
                      <p className="extra_small_txt">July 8, 2020</p>
                      <p className="gnrl_para mt-2 mb-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className="float-left w-100">
                        <button className="blog_btn">
                          View <img src={blogBtnArrow} alt="" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog_main_box">
                    <div className="blog_main_box_img">
                      <img src={blog2} alt="" />
                    </div>
                    <div className="blog_main_box_txt">
                      <p className="mediam_txt">UI/UX Design</p>
                      <p className="extra_small_txt">July 8, 2020</p>
                      <p className="gnrl_para mt-2 mb-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className="float-left w-100">
                        <button className="blog_btn">
                          View <img src={blogBtnArrow} alt="" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog_main_box">
                    <div className="blog_main_box_img">
                      <img src={blog3} alt="" />
                    </div>
                    <div className="blog_main_box_txt">
                      <p className="mediam_txt">UI/UX Design</p>
                      <p className="extra_small_txt">UI/UX July 8, 2020&gt;</p>
                      <p className="gnrl_para mt-2 mb-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className="float-left w-100">
                        <button className="blog_btn">
                          View <img src={blogBtnArrow} alt="" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="get_free_quot_sec mt-5 pb-2 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <p className="section_title">
                Ask for a <span>Proposal</span> – fill out simple form and we
                will get back to you
              </p>
            </div>
            <div className="col-lg-3 col-md-12 text-right">
              <button
                className="gnrl_red_btn"
                data-toggle="modal"
                data-target="#get_free_qout"
              >
                Get free quote
                <img src={whiteArrow} alt="" />
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* The Modal */}
      <div className="modal" id="get_free_qout">
        <div className="modal-dialog">
          <div className="modal-content p-2">
            {/* Modal Header */}
            <div className="modal-header w-100 float-left border-0 pb-0">
              <p className="section_title">
                Inquiry <span>Form</span>
              </p>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body w-100 float-left">
              <div className="apply_for_job">
                <input type="text" placeholder="Your Name" />
                <input type="email" placeholder="Your Email" />
                <textarea placeholder="Your Message" defaultValue={""} />
                <button className="gnrl_red_btn mt-4">Submit</button>
              </div>
            </div>
            {/* Modal footer */}
            {/* <div class="modal-footer w-100 float-left">
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default BlogDetails;

import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from '../View/AboutUs/AboutUs';
import Blog from '../View/Blog/Blog';
import BlogDetails from '../View/Blog/BlogDetails';
import Career from '../View/Career/Career';
import ContactUs from '../View/ContactUs/ContactUs';
import DefaultFooter from '../View/DefaultLayout/DefaultFooter';
import DefaultHeader from '../View/DefaultLayout/DefaultHeader';
import Event from '../View/Event/Event';
import EventDetails from '../View/Event/EventDetails';
import HomePage from '../View/HomePage/HomePage';
import ProjectDetails from '../View/ProjectDetails/ProjectDetails';
import AppDevelopment from '../View/Services/AppDevelopment';
import GameDevelopment from '../View/Services/GameDevelopment';
import GraphicsDesign from '../View/Services/GraphicsDesign';
import Outsource from '../View/Services/Outsource';
import QualityAssurance from '../View/Services/QualityAssurance';
import SEO from '../View/Services/SEO';
import UIUXDesign from '../View/Services/UIUXDesign';
import WebDevelopment from '../View/Services/WebDevelopment';
import Testimonial from '../View/Testimonial/Testimonial';
import Work from '../View/Work/Work';

export default function AppRoutes() {
  return (
    <Fragment>
      <Router>
        <DefaultHeader />
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/about-us' component={AboutUs} />
          <Route exact path='/contact-us' component={ContactUs} />
          <Route exact path='/work' component={Work} />
          <Route exact path='/event' component={Event} />
          <Route exact path='/event-details' component={EventDetails} />
          {/* Services routes start */}
          <Route exact path='/uiux-design' component={UIUXDesign} />
          <Route exact path='/web-development' component={WebDevelopment} />
          <Route exact path='/app-development' component={AppDevelopment} />
          <Route exact path='/graphics-design' component={GraphicsDesign} />
          <Route exact path='/outsource' component={Outsource} />
          <Route exact path='/seo' component={SEO} />
          <Route exact path='/quality-assurance' component={QualityAssurance} />
          <Route exact path='/game-development' component={GameDevelopment} />
          {/* Services routes end */}
          <Route exact path='/blog' component={Blog} />
          <Route exact path='/blog-details' component={BlogDetails} />
          <Route exact path='/project-details' component={ProjectDetails} />
          <Route exact path='/testimonial' component={Testimonial} />
          <Route exact path='/career' component={Career} />
        </Switch>
        <DefaultFooter />
      </Router>
    </Fragment>
  );
}

import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  careerIllu,
  time,
  dress,
  empower,
  whiteArrow,
  job,
} from "../../AllImages/AllImages";

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div className="career_01 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInLeft">
              <p className="section_title">
                <span>Career </span>You Love
              </p>
              <p className="gnrl_para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInRight">
              <img src={careerIllu} alt="" />
            </div>
          </div>
        </div>
      </div>
      <section
        className="career_02 mt-5 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p className="section_title">
                The <span>Perks</span>
              </p>
              <p className="gnrl_para mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5">
              <span className="develpo_pro_icon1">
                <img src={time} alt="" />
              </span>
              <p className="mediam_txt mt-2">Flexible working Hours</p>
              <p className="gnrl_para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5">
              <span className="develpo_pro_icon2">
                <img src={dress} alt="" />
              </span>
              <p className="mediam_txt mt-2">Informal Dress Code</p>
              <p className="gnrl_para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5">
              <span className="develpo_pro_icon3">
                <img src={empower} alt="" />
              </span>
              <p className="mediam_txt mt-2">Better Empowerment</p>
              <p className="gnrl_para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="career_03 mt-5 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="section_title">
                <span>Friendly</span> and <span>warm</span> environment
                <br />
                you will enjoy to work and progress
              </p>
              <p className="gnrl_para mt-3">
                SaturnCube Technologies is an enthusiastic and innovation-driven
                team who enjoys challenges and new perspectives. We employ
                talented professionals in all areas of mobile device development
                from software developers to user experience designers. By
                joining SaturnCube Technologies you will be gaining an
                international experience with a global network of customers and
                partners, continuous educational opportunities.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="career_04 mt-5 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 text-center">
              <img src={job} alt="" />
            </div>
            <div className="col-lg-7 col-md-7">
              <p className="section_title">
                Couldn’t find the job you are looking for?
              </p>
              <p className="gnrl_para">
                Send your resume on<Link to="#"> hr@saturncube.com</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="career_05 mt-5 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p className="section_title">
                <span>Current </span>openings
              </p>
              <p className="gnrl_para mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s.
              </p>
            </div>
            <div className="col-lg-12">
              <div id="accordion" className="accordion">
                <div className="card mb-0">
                  <div
                    className="card-header collapsed mt-2"
                    data-toggle="collapse"
                    href="#collapseOne"
                  >
                    <span className="card-title">
                      UI/UX designer
                      <span>(Min 2 Years of experience required)</span>
                    </span>
                  </div>
                  <div
                    id="collapseOne"
                    className="card-body collapse"
                    data-parent="#accordion"
                  >
                    <p className="card-body-title">Role Overview :</p>
                    <ul>
                      <li>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s
                      </li>
                    </ul>
                    <p className="card-body-title">Responsibilities :</p>
                    <ul>
                      <li>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </li>
                      <li>
                        The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters
                      </li>
                      <li>
                        as opposed to using 'Content here, content here', making
                        it look like readable English.
                      </li>
                      <li>
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum
                      </li>
                      <li>
                        lorem ipsum doler as opposed to using 'Content here,
                        content here', making it look like readable English.
                      </li>
                    </ul>
                    <p className="card-body-title">Requirements :</p>
                    <ul>
                      <li>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </li>
                      <li>
                        The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters
                      </li>
                      <li>
                        as opposed to using 'Content here, content here', making
                        it look like readable English.
                      </li>
                      <li>
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum
                      </li>
                      <li>
                        lorem ipsum doler as opposed to using 'Content here,
                        content here', making it look like readable English.
                      </li>
                    </ul>
                    <span className="w-100 float-left mt-3">
                      <button
                        className="gnrl_red_btn"
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        Apply now
                        <img src={whiteArrow} alt="" />
                      </button>
                    </span>
                  </div>
                  <div
                    className="card-header collapsed mt-2"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseTwo"
                  >
                    <span className="card-title">
                      React.js Developer
                      <span>(Min 2 Years of experience required)</span>
                    </span>
                  </div>
                  <div
                    id="collapseTwo"
                    className="card-body collapse"
                    data-parent="#accordion"
                  >
                    <p className="card-body-title">Role Overview :</p>
                    <ul>
                      <li>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s
                      </li>
                    </ul>
                    <p className="card-body-title">Responsibilities :</p>
                    <ul>
                      <li>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </li>
                      <li>
                        The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters
                      </li>
                      <li>
                        as opposed to using 'Content here, content here', making
                        it look like readable English.
                      </li>
                      <li>
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum
                      </li>
                      <li>
                        lorem ipsum doler as opposed to using 'Content here,
                        content here', making it look like readable English.
                      </li>
                    </ul>
                    <p className="card-body-title">Requirements :</p>
                    <ul>
                      <li>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </li>
                      <li>
                        The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters
                      </li>
                      <li>
                        as opposed to using 'Content here, content here', making
                        it look like readable English.
                      </li>
                      <li>
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum
                      </li>
                      <li>
                        lorem ipsum doler as opposed to using 'Content here,
                        content here', making it look like readable English.
                      </li>
                    </ul>
                    <span className="w-100 float-left mt-3">
                      <button className="gnrl_red_btn">
                        Apply now
                        <img src={whiteArrow} alt="" />
                      </button>
                    </span>
                  </div>
                  <div
                    className="card-header collapsed mt-2"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseThree"
                  >
                    <span className="card-title">
                      Node.js Developer
                      <span>(Min 2 Years of experience required)</span>
                    </span>
                  </div>
                  <div
                    id="collapseThree"
                    className="card-body collapse"
                    data-parent="#accordion"
                  >
                    <p className="card-body-title">Role Overview :</p>
                    <ul>
                      <li>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s
                      </li>
                    </ul>
                    <p className="card-body-title">Responsibilities :</p>
                    <ul>
                      <li>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </li>
                      <li>
                        The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters
                      </li>
                      <li>
                        as opposed to using 'Content here, content here', making
                        it look like readable English.
                      </li>
                      <li>
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum
                      </li>
                      <li>
                        lorem ipsum doler as opposed to using 'Content here,
                        content here', making it look like readable English.
                      </li>
                    </ul>
                    <p className="card-body-title">Requirements :</p>
                    <ul>
                      <li>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </li>
                      <li>
                        The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters
                      </li>
                      <li>
                        as opposed to using 'Content here, content here', making
                        it look like readable English.
                      </li>
                      <li>
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum
                      </li>
                      <li>
                        lorem ipsum doler as opposed to using 'Content here,
                        content here', making it look like readable English.
                      </li>
                    </ul>
                    <span className="w-100 float-left mt-3">
                      <button className="gnrl_red_btn">
                        Apply now
                        <img src={whiteArrow} alt="" />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* The Modal */}
      <div className="modal" id="myModal">
        <div className="modal-dialog">
          <div className="modal-content p-2">
            {/* Modal Header */}
            <div className="modal-header w-100 float-left border-0 pb-0">
              <p className="section_title">
                Application <span>Form</span>
              </p>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body w-100 float-left">
              <div className="apply_for_job">
                <input type="text" placeholder="Name" />
                <input type="email" placeholder="Email" />
                <input type="text" placeholder="Mobile" />
                <input type="file" id="myFile" name="filename2" />
                <button className="gnrl_red_btn mt-4">Submit</button>
              </div>
            </div>
            {/* Modal footer */}
            {/* <div class="modal-footer w-100 float-left">
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Career;

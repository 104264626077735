import React, { Fragment, useEffect, useState } from "react";
import { whiteArrow } from "../../AllImages/AllImages";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const EventDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [openImage, setopenImage] = useState(false);
  const [photoIndex, setphotoIndex] = useState(4);

  const imageData = [
    "https://content3.jdmagicbox.com/comp/pune/g1/020pxx20.xx20.100216092855.h7g1/catalogue/aarya-design-sadashiv-peth-pune-interior-designers-mzkchtgr8o.jpg",
    "https://rimage.gnst.jp/rest/img/4pkmex790000/s_0n7n.jpg?t=1581998004",
    "https://i.pinimg.com/originals/2b/38/ef/2b38ef642c97a357cd86880b01ffec28.jpg",
    "https://i.pinimg.com/originals/0a/8f/7f/0a8f7f1318aa651f5db338a572652cce.jpg",
    "https://img.pr.com/release/2005/457205/pressrelease_original_457205_1590511784.jpeg",
    "https://www.easyoffices.com/blog/wp-content/uploads/2020/05/EO-3.jpg",
    "https://www.surfacequest.com/wp-content/uploads/2016/10/7700-France-512.jpg",
    "https://content3.jdmagicbox.com/comp/pune/g1/020pxx20.xx20.100216092855.h7g1/catalogue/aarya-design-sadashiv-peth-pune-interior-designers-f7nhd3qfne.jpg",
    "https://lh3.googleusercontent.com/-isPoBF8y3--KfTzwbP4tRkBhZ_5CeyO1UCN3_-1ZGEMXEq27GUniG6UHXRxWfwGE-8",
    "https://i.pinimg.com/originals/0a/8f/7f/0a8f7f1318aa651f5db338a572652cce.jpg",
    "https://lh3.googleusercontent.com/25779m6oW0Rdrjy7ey52663x6kjsYAQxPwNC2ORYyijCNht0Ys9nvGR6YNgLTqOxHDs",
    "https://www.easyoffices.com/blog/wp-content/uploads/2020/05/EO-2.jpg",
    "https://img.pr.com/release/2005/457205/pressrelease_original_457205_1590511784.jpeg",
    "https://rimage.gnst.jp/rest/img/4pkmex790000/s_0n7n.jpg?t=1581998004",
    "https://img.pr.com/release/2005/457205/pressrelease_original_457205_1590511784.jpeg",
    "https://content3.jdmagicbox.com/comp/pune/g1/020pxx20.xx20.100216092855.h7g1/catalogue/aarya-design-sadashiv-peth-pune-interior-designers-f7nhd3qfne.jpg",
    "https://lh3.googleusercontent.com/25779m6oW0Rdrjy7ey52663x6kjsYAQxPwNC2ORYyijCNht0Ys9nvGR6YNgLTqOxHDs",
    "https://img.pr.com/release/2005/457205/pressrelease_original_457205_1590511784.jpeg",
  ];
  const imgModal = (img) => {
    const index = imageData.indexOf(img);

    setopenImage(!openImage);
    setphotoIndex(index);
  };
  return (
    <Fragment>
      <section className="about_sec06 mt-5">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 text-center wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <p className="page_title">Event title</p>
              <p className="gnrl_para mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
                <br />
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make.
              </p>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="gallery">
                  {/* first Row start */}
                  <div className="float-left w-100">
                    {imageData.slice(0, 4).map((image, i) => (
                      <div
                        key={i}
                        className="cutm_wid_25 cstm_he_wi"
                        onClick={(e) => imgModal(image)}
                      >
                        <figure className="figure">
                          <img src={image} alt="" />
                          <figcaption>
                            Daytona Beach <small>United States</small>
                          </figcaption>
                        </figure>
                      </div>
                    ))}
                  </div>
                  {/* first Row end */}
                  {/* Second Row start */}
                  <div className="cutm_wid_50 cstm_he_wi">
                    <div className="float-left w-100">
                      {/* 1st part (4 images) start */}
                      <div className="cutm_wid_50 cstm_he_wi">
                        {imageData.slice(4, 8).map((image, i) => (
                          <div
                            key={i}
                            className="cutm_wid_50 cstm_he_wi"
                            onClick={(e) => imgModal(image)}
                          >
                            <figure className="figure">
                              <img src={image} alt="" />
                              <figcaption>
                                Daytona Beach <small>United States</small>
                              </figcaption>
                            </figure>
                          </div>
                        ))}
                      </div>
                      {/* 1st part (4 images) stop */}

                      {/* 2nd part (1 images) start */}
                      {imageData.slice(9, 10).map((image, i) => (
                        <div
                          key={i}
                          className="cutm_wid_50 cstm_he_wi"
                          onClick={(e) => imgModal(image)}
                        >
                          <figure className="figure">
                            <img src={image} alt="" />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                      ))}
                    </div>
                    {/* 2nd part (1 images) end */}
                    {/* Second Row start */}

                    {/* Third Row start */}

                    <div className="float-left w-100">
                      {/* 3rd part (1 images left) start */}
                      {imageData.slice(11, 12).map((image, i) => (
                        <div
                          key={i}
                          className="cutm_wid_50 cstm_he_wi"
                          onClick={(e) => imgModal(image)}
                        >
                          <figure className="figure">
                            <img src={image} alt="" />
                            <figcaption>
                              Daytona Beach <small>United States</small>
                            </figcaption>
                          </figure>
                        </div>
                      ))}

                      {/* 3rd part (1 images left) end */}

                      {/* 3rd part (4 images ) start */}

                      <div className="cutm_wid_50 cstm_he_wi">
                        {imageData.slice(13, 17).map((image, i) => (
                          <div
                            key={i}
                            className="cutm_wid_50 cstm_he_wi"
                            onClick={(e) => imgModal(image)}
                          >
                            <figure className="figure">
                              <img src={image} alt="" />
                              <figcaption>
                                Daytona Beach <small>United States</small>
                              </figcaption>
                            </figure>
                          </div>
                        ))}
                      </div>
                      {/* 3rd part (4 images ) start */}
                    </div>
                  </div>
                  {/* Third Row end*/}
                  {/* big Image start */}
                  {imageData.slice(17, 18).map((image, i) => (
                    <div
                      key={i}
                      className="cutm_wid_50 cstm_he_wi"
                      onClick={(e) => imgModal(image)}
                    >
                      <figure className="figure">
                        <img src={image} alt="" />
                        <figcaption>
                          Daytona Beach <small>United States</small>
                        </figcaption>
                      </figure>
                    </div>
                  ))}
                  {/* big Image end */}
                </div>
                {openImage && (
                  <Lightbox
                    mainSrc={imageData[photoIndex]}
                    nextSrc={imageData[(photoIndex + 1) % imageData.length]}
                    prevSrc={
                      imageData[
                        (photoIndex + imageData.length - 1) % imageData.length
                      ]
                    }
                    onCloseRequest={() => {
                      setopenImage(false);
                      // setphotoIndex(
                      //   (photoIndex + imageData.length - 1) % imageData.length
                      // );
                    }}
                    onMovePrevRequest={() =>
                      setphotoIndex(
                        (photoIndex + imageData.length - 1) % imageData.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setphotoIndex((photoIndex + 1) % imageData.length)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="get_free_quot_sec mt-5 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <p className="section_title">
                Ask for a <span>Proposal</span> – fill out simple form and we
                will get back to you
              </p>
            </div>
            <div className="col-lg-3 col-md-12 text-right">
              <button
                className="gnrl_red_btn"
                data-toggle="modal"
                data-target="#get_free_qout"
              >
                Get free quote
                <img src={whiteArrow} alt="" />
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* The Modal */}
      <div className="modal" id="get_free_qout">
        <div className="modal-dialog">
          <div className="modal-content p-2">
            {/* Modal Header */}
            <div className="modal-header w-100 float-left border-0 pb-0">
              <p className="section_title">
                Inquiry <span>Form</span>
              </p>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body w-100 float-left">
              <div className="apply_for_job">
                <input type="text" placeholder="Your Name" />
                <input type="email" placeholder="Your Email" />
                <textarea placeholder="Your Message" defaultValue={""} />
                <button className="gnrl_red_btn mt-4">Submit</button>
              </div>
            </div>
            {/* Modal footer */}
            {/* <div class="modal-footer w-100 float-left">
         <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EventDetails;

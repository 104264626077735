import React, { useEffect } from "react";
import { Fragment } from "react";
import {
  android,
  apple,
  flutter,
  projectPage1,
  whiteArrow,
} from "../../AllImages/AllImages";

const ProjectDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <section className="pro_details_01 mt-5">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 text-left wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <p className="section_title">Project name</p>
              <p className="gnrl_para">Type of project</p>
            </div>
            <div
              className="col-lg-12 mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="pro_details_01_img">
                <img src={projectPage1} alt="" />
              </div>
            </div>
            <div
              className="col-lg-12 mt-5 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <span className="pro_details_01_technology">
                <p className="gnrl_para">Technology we used</p>
                <img src={flutter} alt="" />
              </span>
              <span
                className="pro_details_01_technology ml-3 wow animate__animated animate__fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="gnrl_para">Platform</p>
                <img src={android} alt="" />
                <img src={apple} alt="" />
              </span>
            </div>
            <div
              className="col-lg-12 wow animate__animated animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <p className="mediam_txt mt-5">Phase 1: Lorem ipsum</p>
              <p className="gnrl_para  mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.Contrary to popular belief, Lorem Ipsum is not
                simply random text. It has roots in a piece of classical Latin
                literature from 45 BC, making it over 2000 years old. Richard
                McClintock, a Latin professor at Hampden-Sydney College in
                Virginia, looked up one of the more obscure Latin words,
                consectetur,
              </p>
              <p className="mediam_txt mt-5">Phase 2: Lorem ipsum</p>
              <p className="gnrl_para  mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.Contrary to popular belief, Lorem Ipsum is not
                simply random text. It has roots in a piece of classical Latin
                literature from 45 BC, making it over 2000 years old. Richard
                McClintock, a Latin professor at Hampden-Sydney College in
                Virginia, looked up one of the more obscure Latin words,
                consectetur,
              </p>
              <p className="mediam_txt mt-5">Phase 3: Lorem ipsum</p>
              <p className="gnrl_para  mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.Contrary to popular belief, Lorem Ipsum is not
                simply random text. It has roots in a piece of classical Latin
                literature from 45 BC, making it over 2000 years old. Richard
                McClintock, a Latin professor at Hampden-Sydney College in
                Virginia, looked up one of the more obscure Latin words,
                consectetur,
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="get_free_quot_sec mt-5 pb-2 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <p className="section_title">
                Ask for a <span>Proposal</span> – fill out simple form and we
                will get back to you
              </p>
            </div>
            <div className="col-lg-3 col-md-12 text-right">
              <button
                className="gnrl_red_btn"
                data-toggle="modal"
                data-target="#get_free_qout"
              >
                Get free quote
                <img src={whiteArrow} alt="" />
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* The Modal */}
      <div className="modal" id="get_free_qout">
        <div className="modal-dialog">
          <div className="modal-content p-2">
            {/* Modal Header */}
            <div className="modal-header w-100 float-left border-0 pb-0">
              <p className="section_title">
                Inquiry <span>Form</span>
              </p>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body w-100 float-left">
              <div className="apply_for_job">
                <input type="text" placeholder="Your Name" />
                <input type="email" placeholder="Your Email" />
                <textarea placeholder="Your Message" defaultValue={""} />
                <button className="gnrl_red_btn mt-4">Submit</button>
              </div>
            </div>
            {/* Modal footer */}
            {/* <div class="modal-footer w-100 float-left">
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ProjectDetails;

import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  blog,
  blogBtnArrow,
  blog1,
  blog2,
  whiteArrow,
} from "../../AllImages/AllImages";

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <section className="blog_01 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInLeft">
              <p className="section_title">
                <span>Blog</span>
              </p>
              <p className="gnrl_para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInRight">
              <img src={blog} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="blog_main mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-11">
              <div className="row">
                <div
                  className="col-lg-4 col-md-4 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog_main_box">
                    <div className="blog_main_box_img">
                      <img src={blog1} alt="" />
                    </div>
                    <div className="blog_main_box_txt">
                      <p className="mediam_txt">UI/UX Design</p>
                      <p className="extra_small_txt">July 8, 2020</p>
                      <p className="gnrl_para mt-2 mb-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className="float-left w-100">
                        <Link to="/blog-details">
                          <button className="blog_btn">
                            View <img src={blogBtnArrow} alt="" />
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog_main_box">
                    <div className="blog_main_box_img">
                      <img src={blog2} alt="" />
                    </div>
                    <div className="blog_main_box_txt">
                      <p className="mediam_txt">UI/UX Design</p>
                      <p className="extra_small_txt">July 8, 2020</p>
                      <p className="gnrl_para mt-2 mb-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className="float-left w-100">
                        <Link to="/blog-details">
                          <button className="blog_btn">
                            View <img src={blogBtnArrow} alt="" />
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog_main_box">
                    <div className="blog_main_box_img">
                      <img src={blog1} alt="" />
                    </div>
                    <div className="blog_main_box_txt">
                      <p className="mediam_txt">UI/UX Design</p>
                      <p className="extra_small_txt">UI/UX July 8, 2020&gt;</p>
                      <p className="gnrl_para mt-2 mb-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className="float-left w-100">
                        <Link to="/blog-details">
                          <button className="blog_btn">
                            View <img src={blogBtnArrow} alt="" />
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog_main_box">
                    <div className="blog_main_box_img">
                      <img src={blog1} alt="" />
                    </div>
                    <div className="blog_main_box_txt">
                      <p className="mediam_txt">UI/UX Design</p>
                      <p className="extra_small_txt">July 8, 2020</p>
                      <p className="gnrl_para mt-2 mb-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className="float-left w-100">
                        <Link to="/blog-details">
                          <button className="blog_btn">
                            View <img src={blogBtnArrow} alt="" />
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog_main_box">
                    <div className="blog_main_box_img">
                      <img src={blog2} alt="" />
                    </div>
                    <div className="blog_main_box_txt">
                      <p className="mediam_txt">UI/UX Design</p>
                      <p className="extra_small_txt">July 8, 2020</p>
                      <p className="gnrl_para mt-2 mb-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className="float-left w-100">
                        <Link to="/blog-details">
                          <button className="blog_btn">
                            View <img src={blogBtnArrow} alt="" />
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog_main_box">
                    <div className="blog_main_box_img">
                      <img src={blog1} alt="" />
                    </div>
                    <div className="blog_main_box_txt">
                      <p className="mediam_txt">UI/UX Design</p>
                      <p className="extra_small_txt">UI/UX July 8, 2020&gt;</p>
                      <p className="gnrl_para mt-2 mb-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting this is a dunny text here sinc 1863
                        industry. Lorem Ipsum doler sit amet this is adummy text
                        sinc 1920.
                      </p>
                      <span className="float-left w-100">
                        <Link to="/blog-details">
                          <button className="blog_btn">
                            View <img src={blogBtnArrow} alt="" />
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="get_free_quot_sec mt-5 wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <p className="section_title">
                Ask for a <span>Proposal</span> – fill out simple form and we
                will get back to you
              </p>
            </div>
            <div className="col-lg-3 col-md-12 text-right">
              <button
                className="gnrl_red_btn"
                data-toggle="modal"
                data-target="#get_free_qout"
              >
                Get free quote
                <img src={whiteArrow} alt="" />
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* The Modal */}
      <div className="modal" id="get_free_qout">
        <div className="modal-dialog">
          <div className="modal-content p-2">
            {/* Modal Header */}
            <div className="modal-header w-100 float-left border-0 pb-0">
              <p className="section_title">
                Inquiry <span>Form</span>
              </p>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body w-100 float-left">
              <div className="apply_for_job">
                <input type="text" placeholder="Your Name" />
                <input type="email" placeholder="Your Email" />
                <textarea placeholder="Your Message" defaultValue={""} />
                <button className="gnrl_red_btn mt-4">Submit</button>
              </div>
            </div>
            {/* Modal footer */}
            {/* <div class="modal-footer w-100 float-left">
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Blog;
